import React, { FC, PropsWithChildren, useMemo } from 'react';
import Styles from './ContentBlock.module.less';

const styles: any = Styles;
const BASE_LEVEL = 3;

const blockStyles: any = {
  title: [
    styles.contentBlockTitleLevel1,
    styles.contentBlockTitleLevel2,
    styles.contentBlockTitleLevel3,
    styles.contentBlockTitleLevel4,
    styles.contentBlockTitleLevel5,
    styles.contentBlockTitleLevel6,
    styles.contentBlockTitleLevel7,
  ],
  other: [
    styles.contentBlockTextLevel1,
    styles.contentBlockTextLevel2,
    styles.contentBlockTextLevel3,
    styles.contentBlockTextLevel4,
    styles.contentBlockTextLevel5,
    styles.contentBlockTextLevel6,
    styles.contentBlockTextLevel7,
    styles.contentBlockTextLevel8,
    styles.contentBlockTextLevel9,
  ],
};

const gapStyles: any = {
  sm: styles.contentBlockGapSm,
  md: styles.contentBlockGapMd,
  lg: styles.contentBlockGapLg,
  xl: styles.contentBlockGapXl,
};

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  align?: 'top' | 'bottom' | 'center' | 'left' | 'right';
  // type?: 'title' | 'normal' | 'primary' | 'secondary';
  heading?: boolean;
  primary?: boolean;
  danger?: boolean;
  normal?: boolean;
  secondary?: boolean;
  level?: number;
  strong?: boolean;
  gap?: 'none' | 'sm' | 'md' | 'lg' | 'xl';
  vertical?: boolean;
  ellipsis?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const ContentBlock: FC<Props> = (props) => {
  const { right, left, children, align } = props;
  const alignItems = useMemo(() => {
    if (!align || align === 'top') {
      return 'flex-start';
    } else if (align === 'bottom') {
      return 'flex-end';
    }
    return align;
  }, [align]);

  const className = useMemo(() => {
    const cn = [styles.contentBlock];
    if (props.heading) {
      cn.push(styles.contentBlockTitle);
    }
    if (props.primary) {
      cn.push(styles.contentBlockPrimary);
    }
    if (props.danger) {
      cn.push(styles.contentBlockDanger);
    }
    if (props.secondary) {
      cn.push(styles.contentBlockSecondary);
    }
    if (props.normal) {
      cn.push(styles.contentBlockNormal);
    }
    if (props.strong) {
      cn.push(styles.contentBlockStrong);
    }
    if (props.vertical) {
      cn.push(styles.contentBlockVertical);
    }
    if (props.ellipsis) {
      cn.push(styles.contentBlockEllipsis);
    }
    cn.push(blockStyles[props.heading ? 'title' : 'other'][BASE_LEVEL - (props.level ?? 0)]);
    cn.push(gapStyles[props.gap ?? 'md']);
    if (props.className) {
      cn.push(props.className);
    }

    return cn.join(' ');
  }, [
    props.heading,
    props.primary,
    props.danger,
    props.secondary,
    props.normal,
    props.strong,
    props.vertical,
    props.ellipsis,
    props.level,
    props.gap,
    props.className,
  ]);
  return (
    <div {...props} className={className} style={{ ...(props.style ?? {}), alignItems }}>
      {left && <div className={styles.contentBlockFix}>{left}</div>}
      <div className={styles.contentBlockContent}>{children}</div>
      {right && <div className={styles.contentBlockFix}>{right}</div>}
    </div>
  );
};

type TextBlockProps = {
  heading?: boolean;
  primary?: boolean;
  danger?: boolean;
  normal?: boolean;
  secondary?: boolean;
  level?: number;
  gap?: 'none' | 'sm' | 'md' | 'lg' | 'xl';
  strong?: boolean;
  align?: 'left' | 'center' | 'right';
  ellipsis?: boolean;
  id?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const TextBlock: FC<PropsWithChildren<TextBlockProps>> = (props) => {
  const { children } = props;

  const className = useMemo(() => {
    const cn = [styles.textBlock];
    if (props.heading) {
      cn.push(styles.contentBlockTitle);
    }
    if (props.primary) {
      cn.push(styles.contentBlockPrimary);
    }
    if (props.danger) {
      cn.push(styles.contentBlockDanger);
    }
    if (props.normal) {
      cn.push(styles.contentBlockNormal);
    }
    if (props.secondary) {
      cn.push(styles.contentBlockSecondary);
    }
    if (props.strong) {
      cn.push(styles.contentBlockStrong);
    }
    if (props.ellipsis) {
      cn.push(styles.contentBlockEllipsis);
    }
    cn.push(blockStyles[props.heading ? 'title' : 'other'][BASE_LEVEL - (props.level ?? 0)]);
    cn.push(gapStyles[props.gap ?? 'md']);
    if (props.className) {
      cn.push(props.className);
    }
    return cn.join(' ');
  }, [
    props.heading,
    props.primary,
    props.danger,
    props.normal,
    props.secondary,
    props.strong,
    props.ellipsis,
    props.level,
    props.gap,
    props.className,
  ]);
  return (
    <div
      className={className}
      id={props.id}
      style={{ textAlign: props.align ?? 'left', ...(props.style ?? {}) }}>
      {children}
    </div>
  );
};

export const TextSpan: FC<TextBlockProps> = (props) => {
  const { children } = props;

  const className = useMemo(() => {
    const cn = [styles.textBlock];
    if (props.heading) {
      cn.push(styles.contentBlockTitle);
    }
    if (props.primary) {
      cn.push(styles.contentBlockPrimary);
    }
    if (props.danger) {
      cn.push(styles.contentBlockDanger);
    }
    if (props.normal) {
      cn.push(styles.contentBlockNormal);
    }
    if (props.secondary) {
      cn.push(styles.contentBlockSecondary);
    }
    cn.push(blockStyles[props.heading ? 'title' : 'other'][BASE_LEVEL - (props.level ?? 0)]);
    cn.push(gapStyles[props.gap ?? 'md']);
    if (props.className) {
      cn.push(props.className);
    }
    if (props.strong) {
      cn.push(styles.contentBlockStrong);
    }
    if (props.ellipsis) {
      cn.push(styles.contentBlockEllipsis);
    }
    return cn.join(' ');
  }, [
    props.heading,
    props.primary,
    props.danger,
    props.normal,
    props.secondary,
    props.level,
    props.gap,
    props.className,
    props.strong,
    props.ellipsis,
  ]);
  return <span className={className}>{children}</span>;
};

export const TextParagraph: FC<TextBlockProps> = (props) => {
  const { children } = props;

  const className = useMemo(() => {
    const cn = [styles.textBlock];
    if (props.heading) {
      cn.push(styles.contentBlockTitle);
    }
    if (props.primary) {
      cn.push(styles.contentBlockPrimary);
    }
    if (props.danger) {
      cn.push(styles.contentBlockDanger);
    }
    if (props.secondary) {
      cn.push(styles.contentBlockSecondary);
    }
    if (props.normal) {
      cn.push(styles.contentBlockNormal);
    }
    if (props.strong) {
      cn.push(styles.contentBlockStrong);
    }
    cn.push(blockStyles[props.heading ? 'title' : 'other'][BASE_LEVEL - (props.level ?? 0)]);
    cn.push(gapStyles[props.gap ?? 'md']);
    if (props.className) {
      cn.push(props.className);
    }
    if (props.ellipsis) {
      cn.push(styles.contentBlockEllipsis);
    }
    return cn.join(' ');
  }, [
    props.heading,
    props.primary,
    props.danger,
    props.secondary,
    props.normal,
    props.strong,
    props.level,
    props.gap,
    props.className,
    props.ellipsis,
  ]);
  return <p className={className}>{children}</p>;
};

export const TextHeading: FC<TextBlockProps> = (props) => {
  const { children } = props;

  const className = useMemo(() => {
    const cn = [styles.textHeading];
    if (props.heading) {
      cn.push(styles.contentBlockTitle);
    }
    if (props.primary) {
      cn.push(styles.contentBlockPrimary);
    }
    if (props.danger) {
      cn.push(styles.contentBlockDanger);
    }
    if (props.normal) {
      cn.push(styles.contentBlockNormal);
    }
    if (props.secondary) {
      cn.push(styles.contentBlockSecondary);
    }
    if (props.strong) {
      cn.push(styles.contentBlockStrong);
    }
    if (props.ellipsis) {
      cn.push(styles.contentBlockEllipsis);
    }
    cn.push(blockStyles[props.heading ? 'title' : 'other'][BASE_LEVEL - (props.level ?? 0)]);
    cn.push(gapStyles[props.gap ?? 'md']);
    if (props.className) {
      cn.push(props.className);
    }
    return cn.join(' ');
  }, [
    props.heading,
    props.primary,
    props.danger,
    props.normal,
    props.secondary,
    props.strong,
    props.ellipsis,
    props.level,
    props.gap,
    props.className,
  ]);
  return (
    <h1 id={props.id} className={className}>
      {children}
    </h1>
  );
};
