import { Button, Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import { MobileVerification } from '../../models/UserModels';
import styles from './MobileVerificationInput.module.less';
import { CloseCircleFilled, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { PhoneInput } from '../PhoneInput/PhoneInput';

type Props = {
  value?: MobileVerification;
  onChange?: (value?: MobileVerification) => void;
  placeholder?: string;
  defaultCountry?: string;
  isAdmin?: boolean;
};

export const MobileVerificationInput: FC<Props> = (props) => {
  const { value, onChange, defaultCountry, placeholder, isAdmin } = props;
  const [hide, setHide] = useState<boolean>(props.value?.hide ?? false);
  return (
    <div className={styles.phoneInputWithHide}>
      <PhoneInput
        defaultCountry={defaultCountry}
        placeholder={placeholder}
        verify={!isAdmin}
        onChange={(phone) =>
          onChange?.({
            phone,
            verifiedPhones: [phone],
            hide: value?.hide ?? false,
          })
        }
        value={value?.phone}
      />
      <Button
        type={'link'}
        style={{ display: value?.phone ? undefined : 'none' }}
        className={styles.phoneInputClose}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          props.onChange?.(undefined);
        }}>
        <CloseCircleFilled style={{ color: '#ddd' }} />
      </Button>
      <Tooltip title={hide ? 'Not visible to Recruiters' : 'Visible to Recruiters'}>
        <Button
          disabled={value?.phone === undefined}
          className={styles.phoneInputEye}
          type={'link'}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setHide(!hide);
            if (value) {
              onChange?.({ ...value, hide: !hide });
            }
          }}>
          {hide ? (
            <EyeInvisibleOutlined style={{ color: value?.phone ? 'red' : undefined }} />
          ) : (
            <EyeOutlined />
          )}
        </Button>
      </Tooltip>
    </div>
  );
};
