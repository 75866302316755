import React, { FC, useCallback, useEffect, useState } from 'react';
import { Divider, InputNumber, Select, Space } from 'antd';
import { SalaryRange } from '../../models/UserModels';
import styles from './SalaryInput.module.less';
import { useCachedData } from '../../connection/CacheContext';

const { Option } = Select;

type Props = {
  value?: SalaryRange;
  onChange?: (value?: SalaryRange) => void;
};
export const SalaryInputRange: FC<Props> = ({ value, onChange }) => {
  const [allCurrency, setAllCurrency] = useState<any[]>([]);
  const [type, setType] = useState<string>(value?.type ?? 'Month');
  const [currency, setCurrency] = useState<string>(value?.currency ?? '1');
  const [amount, setAmount] = useState<number>();
  const [fromAmount, setFromAmount] = useState<number>();
  const { mainType, masterdata } = useCachedData();

  const handleChange = useCallback(
    (val: any) => {
      if (val && (val.amount > 0 || val.fromAmount > 0)) {
        onChange?.(val);
      } else {
        onChange?.(undefined);
      }
    },
    [onChange],
  );
  const handleToChange = useCallback(
    (val: number) => {
      setAmount(val);
      handleChange({ amount: val!, fromAmount, type, currency });
    },
    [currency, fromAmount, handleChange, type],
  );
  const handleFromChange = useCallback(
    (val: number) => {
      setFromAmount(val);
      if (type === 'Hour') {
        handleChange({ amount: val!, fromAmount: 0, type, currency });
      } else {
        handleChange({ amount, fromAmount: val!, type, currency });
      }
    },
    [amount, currency, handleChange, type],
  );
  const onTypeChange = useCallback(
    (val: string) => {
      setType(val);
      handleChange({ amount, fromAmount, type: val, currency });
    },
    [amount, currency, fromAmount, handleChange],
  );
  const onCurrencyChange = useCallback(
    (val: string) => {
      setCurrency(val);
      handleChange({ amount, fromAmount, type, currency: val });
    },
    [amount, fromAmount, handleChange, type],
  );
  useEffect(() => {
    const currencyId = mainType['Currency'];
    if (currencyId) {
      const options: any[] = [];
      const data: any[] = masterdata[currencyId];
      data.forEach((val) => {
        options.push({ label: val.value, value: val.id });
      });
      setAllCurrency(options);
    }
  }, [mainType, masterdata]);
  useEffect(() => {
    if (value) {
      setType(value?.type ?? 'Month');
      setCurrency(value?.currency ?? '1');
      setAmount(value.amount);
      setFromAmount(value?.fromAmount);
    }
  }, [value]);
  const selectBefore = (
    <Space>
      <Select value={type} className="select-before" onChange={onTypeChange}>
        <Option value="Month">Per Month</Option>
        <Option value="Day">Per Day</Option>
        <Option value="Hour">Per Hour</Option>
        <Option value="Year">Per Year</Option>
      </Select>
      <Divider type={'vertical'} />
      <Select
        showSearch
        value={currency}
        className="select-before"
        onChange={onCurrencyChange}
        options={allCurrency}
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </Space>
  );
  return (
    <div className="flex flex-row w-full">
      <InputNumber
        type="number"
        className={styles.salaryRange}
        min={0}
        value={type === 'Hour' ? value?.amount : value?.fromAmount}
        controls={false}
        placeholder={type === 'Hour' ? 'Amount' : 'From  Amount'}
        onChange={(e: any) => handleFromChange(e)}
        addonBefore={selectBefore}
      />
      {type !== 'Hour' && (
        <InputNumber
          type="number"
          className={styles.toSalaryRange}
          min={0}
          value={(value?.amount as number) > 0 ? value?.amount : undefined}
          controls={false}
          placeholder={'To  Amount'}
          onChange={(e: any) => handleToChange(e)}
        />
      )}
    </div>
  );
};
