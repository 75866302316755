import React, { FC, useMemo } from 'react';
import Utils from '../../utils/Utils';
import 'react-quill/dist/quill.snow.css';
import { TextBlock } from '../../uicomp/atmos/content-block/ContentBlock';
import styles from './RichText.module.less';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

type Props = {
  onChange?: (value?: any) => void;
  value?: any;
  maxLength?: number;
};
const regex = /(<([^>]+)>)/gi;
export const RichText: FC<Props> = (props) => {
  const formats = useMemo(
    () => [
      // 'header',
      'bold',
      'italic',
      'underline',
      // 'strike',
      'list',
      'bullet',
      'indent',
      'link',
      // 'color',
      // 'background',
    ],
    [],
  );
  const modules = useMemo(
    () => ({
      toolbar: [
        // [{ header: [3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        // [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
    }),
    [],
  );
  const p = Utils.useOmitFields(props, 'value', 'onChange');

  const checkCharacterCount = (event: { key: string; preventDefault: () => void }) => {
    // const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (props.value?.length > (props.maxLength ?? 1000000) && event.key !== 'Backspace')
      event.preventDefault();
  };
  return (
    <div>
      <ReactQuill
        onKeyDown={checkCharacterCount}
        modules={modules}
        preserveWhitespace={true}
        value={props.value ? (props.value as string) : ''}
        onChange={(content: any) => props.onChange && props.onChange(content)}
        formats={formats}
        {...p}
      />

      <TextBlock align={'right'} className={styles.richTextCount} secondary level={-2}>
        {props.maxLength
          ? `${props.maxLength - (props.value?.replace(regex, '').length ?? 0)}`
          : `${props.value?.replace(regex, '').length ?? 0}`}
      </TextBlock>
    </div>
  );
};
