import { ApiType, RequestHeader, RequestMessage } from './ApplicationContext';
import superagent from 'superagent';
import config from '../config';
import { NextPageContext } from 'next';
import * as cookie from 'cookie';
import UniversalCookie from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { CookieSetOptions } from 'universal-cookie/cjs/types';

export const AUTH_TOKEN = 'auth-token';
export const REFRESH_TOKEN = 'refresh-token';

let tokenChanging = false;
const universalCookie = new UniversalCookie();
let requestQue: { promiseResolve: any; promiseReject: any }[] = [];

export default class Connection {
  token: string;

  constructor(token: string, private refreshToken?: () => Promise<string>) {
    this.token = token;
  }

  static nextConnection(context: NextPageContext, noAuth?: boolean): Connection {
    const parsedCookies = cookie.parse(context.req?.headers?.cookie ?? '');
    if (!noAuth && typeof window !== 'undefined') {
      return undefined as any;
    }
    return new Connection(noAuth ? (undefined as any) : parsedCookies[AUTH_TOKEN]);
  }

  static nextConnectionNoAuth(): Connection {
    return new Connection(undefined as any);
  }

  sendRequest = async (
    method: (url: string) => superagent.SuperAgentRequest,
    path: string,
    msg?: RequestMessage,
    query?: RequestMessage,
    headers?: RequestHeader,
  ) => {
    if (tokenChanging) {
      await new Promise((resolve, reject) => {
        requestQue.push({ promiseResolve: resolve, promiseReject: reject });
      }).catch(() => {
        throw Error('Invalid Token');
      });
    } else {
      tokenChanging = true;
    }

    return new Promise((resolve, reject) => {
      const url = `${config.api.baseUrl}`;
      this.changeAccessToken(url)
        .then(() => {
          const req = method(`${url}/${path}`).set('Content-Type', 'application/json');
          if (query) {
            req.query(query);
          }
          if (this.token !== undefined && this.token !== null) {
            req.set('Authorization', `Bearer ${this.token}`);
          }
          if (headers) {
            Object.keys(headers).forEach((key) => req.set(key, headers[key]));
          }

          let jsonString = '';
          try {
            jsonString = JSON.stringify(msg);
          } catch (e) {
            throw Error('Invalid JSON object');
          }

          req
            .send(jsonString)
            .then((res: any) => {
              // console.error('SUCCESS', JSON.stringify(res, null, 2));
              resolve(res.body);
            })
            .catch((res: any) => {
              console.error('Error', JSON.stringify(res, null, 2));
              if (res.response) {
                const { response } = res;
                if (response.statusCode) {
                  const { status, statusText } = response;
                  if (res.response.body?.code === 'INVALID_TOKEN') {
                    const universalCookie = new UniversalCookie();
                    universalCookie.remove(AUTH_TOKEN, {
                      path: '/',
                      domain: config.cookieDomain,
                    });
                  } else if (res.response.body?.message) {
                    reject({
                      status,
                      statusText: statusText ?? `${status}`,
                      message: res.response.body?.message,
                      body: res.response.body,
                    });
                  } else {
                    reject({
                      status,
                      statusText: statusText ?? `${status}`,
                      message: statusText ? statusText : 'Unknown',
                      body: res.response.body,
                    });
                  }
                } else {
                  reject({
                    statusCode: 500,
                    statusText: 'Unknown',
                    code: 'Unknown',
                    message: 'Unknown',
                  });
                }
              }
            });
        })
        .catch((x) => {
          console.log(x);
        });
    }) as any;
  };

  changeAccessToken = (url: string) => {
    return new Promise((resolve, reject) => {
      const accessToken = universalCookie.get(AUTH_TOKEN);
      const accessTokenDecode: any = accessToken ? jwtDecode(accessToken) : accessToken;
      const refreshToken = universalCookie.get(REFRESH_TOKEN);
      const refreshTokenDecode: any = refreshToken ? jwtDecode(refreshToken) : refreshToken;
      if (refreshTokenDecode && new Date(refreshTokenDecode?.exp * 1000) >= new Date()) {
        if (this.refreshToken) {
          this.refreshToken()
            .then(async (t) => {
              this.token = t;
              const requestQue1 = [...requestQue];
              tokenChanging = false;
              requestQue = [];
              for (const requestQue1Element of requestQue1) {
                await requestQue1Element.promiseResolve();
              }
              resolve('');
            })
            .catch((reason) => reject(reason));
        } else {
          if (
            !accessTokenDecode ||
            new Date(accessTokenDecode?.exp * 1000 - 60 * 1000) <= new Date()
          ) {
            const req1 = superagent
              .post(`${url}/auth/refresh-access-token`)
              .set('Content-Type', 'application/json');
            req1
              .send({ token: refreshToken })
              .then(async (res) => {
                const token: { accessToken: string; refreshToken: string } = res.body.token;
                // const decoded: any = jwtDecode(token.accessToken);
                const refreshTokenDecoded: any = jwtDecode(token.refreshToken);
                const options: CookieSetOptions = {
                  path: '/',
                  domain: config.cookieDomain,
                  expires: new Date(refreshTokenDecoded.exp * 1000),
                };
                const options1: CookieSetOptions = {
                  ...options,
                  expires: new Date(refreshTokenDecoded.exp * 1000),
                };
                universalCookie.set(AUTH_TOKEN, token.accessToken, options);
                universalCookie.set(REFRESH_TOKEN, token.refreshToken, options1);
                this.token = token.accessToken;
                const requestQue1 = [...requestQue];
                tokenChanging = false;
                requestQue = [];
                for (const requestQue1Element of requestQue1) {
                  await requestQue1Element.promiseResolve();
                }
                resolve('');
              })
              .catch(async (err) => {
                if (err.statusCode === 403 || err.statusCode === 406) {
                  universalCookie.remove(AUTH_TOKEN, { path: '/', domain: config.cookieDomain });
                  universalCookie.remove(REFRESH_TOKEN, {
                    path: '/',
                    domain: config.cookieDomain,
                  });
                }
                const requestQue1 = [...requestQue];
                tokenChanging = false;
                requestQue = [];
                for (const requestQue1Element of requestQue1) {
                  await requestQue1Element.promiseReject();
                }

                reject('');
                //
              });
          } else {
            const requestQue1 = [...requestQue];
            tokenChanging = false;
            requestQue = [];
            for (const requestQue1Element of requestQue1) {
              requestQue1Element.promiseResolve();
            }
            resolve('');
          }
        }
      } else {
        universalCookie.remove(AUTH_TOKEN, { path: '/', domain: config.cookieDomain });
        universalCookie.remove(REFRESH_TOKEN, { path: '/', domain: config.cookieDomain });
        const requestQue1 = [...requestQue];
        tokenChanging = false;
        requestQue = [];
        for (const requestQue1Element of requestQue1) {
          requestQue1Element.promiseResolve();
        }
        resolve('');
      }
    });
  };

  post = (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
    return this.sendRequest(superagent.post, path, msg, query, headers);
  };
  put = (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
    return this.sendRequest(superagent.put, path, msg, query, headers);
  };
  get = (path: string, query?: RequestMessage, headers?: RequestHeader) => {
    return this.sendRequest(superagent.get, path, undefined, query, headers);
  };
  patch = (path: string, msg?: RequestMessage, query?: RequestMessage, headers?: RequestHeader) => {
    return this.sendRequest(superagent.patch, path, msg, query, headers);
  };
  delete = (
    path: string,
    msg?: RequestMessage,
    query?: RequestMessage,
    headers?: RequestHeader,
  ) => {
    return this.sendRequest(superagent.delete, path, msg, query, headers);
  };

  send = (
    api: ApiType,
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => {
    switch (api) {
      case 'post':
        return this.post(path, msg, params, headers);
      case 'get':
        return this.get(path, params ?? msg, headers);
      case 'put':
        return this.put(path, msg, params, headers);
      case 'patch':
        return this.patch(path, msg, params, headers);
      case 'delete':
        return this.delete(path, msg, params, headers);
    }
  };
}
