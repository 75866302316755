import React, { FC, PropsWithChildren } from 'react';
import { Button, Drawer, Grid, Modal, ModalProps, Space } from 'antd';

type Props = ModalProps;

export const ResponsiveModal: FC<PropsWithChildren<Props>> = (props) => {
  const bp = Grid.useBreakpoint();
  if (bp.lg) {
    return (
      <Modal
        destroyOnClose={true}
        width={768}
        {...props}
        maskClosable={false}
        bodyStyle={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          marginRight: -24,
          paddingRight: 24,
        }}
        centered={true}>
        {props.children}
      </Modal>
    );
  }
  return (
    <Drawer
      onClose={(e) => props.onCancel?.(e as any)}
      destroyOnClose={true}
      {...(props as any)}
      footer={
        props.footer ?? (
          <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
            <Button
              // loading={props.confirmLoading}
              onClick={(event) => props.onCancel?.(event as any)}>
              {props.cancelText ?? 'Cancel'}
            </Button>
            <Button
              loading={props.confirmLoading}
              type={'primary'}
              onClick={(event) => props.onOk?.(event as any)}>
              {props.okText ?? 'OK'}
            </Button>
          </Space>
        )
      }
      width={'100%'}>
      {props.children}
    </Drawer>
  );
};
