import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import UniversalCookie from 'universal-cookie';
import { useRouter } from 'next/router';
import jwtDecode from 'jwt-decode';
import { CookiesProvider } from 'react-cookie';
import events from 'events';
import Connection, { AUTH_TOKEN, REFRESH_TOKEN } from './Connection';
import { ApplicationState, Recruiter, UserRecruiter } from '../models/RecruiterModals';
import { Modal } from 'antd';
import Image from 'next/image';
import UnderConstruction from '../public/under-construction.png';
import { CookieSetOptions } from 'universal-cookie/cjs/types';
import config from '../config';
import Utils from '../utils/Utils';
// import { v4 as uuid } from 'uuid';
export type RequestMessage = { [key: string]: any };
export type RequestHeader = { [key: string]: string };
export type ApiType = 'put' | 'get' | 'post' | 'patch' | 'delete';

// export type RecruiterPermission =
//   | 'Edit Profile'
//   | 'Manage Adverts'
//   | 'Manage Cvs'
//   | 'Manage Eyeball'
//   | 'Manage Permissions'
//   | 'Manage Settings'
//   | 'Manage Feedback Forms'
//   | 'Manage Subscriptions'
//   | 'Manage Email Templates'
//   | 'Manage Head Hunt';
//
// export const AvailableRecruiterPermissions = [
//   'Edit Profile',
//   'Manage Adverts',
//   'Manage Cvs',
//   'Manage Eyeball',
//   'Manage Permissions',
//   'Manage Feedback Forms',
//   'Manage Settings',
//   'Manage Subscriptions',
//   'Manage Email Templates',
// ];
export type RecruiterPermission =
  | 'Head Hunt'
  | 'Create Job Adverts'
  | 'Publish & Extend Job Adverts'
  | 'Manage Applicants'
  | 'Notify Applicants via Email / In-app / Feedback'
  | 'Edit Profile'
  | 'Settings'
  | '360 View'
  | 'Change Subscription';

export const AvailableRecruiterPermissions = [
  'Head Hunt',
  'Create Job Adverts',
  'Publish & Extend Job Adverts',
  'Manage Applicants',
  'Notify Applicants via Email / In-app / Feedback',
  'Edit Profile',
  'Settings',
  '360 View',
  'Change Subscription',
];

export type ConnectionApi = {
  send: <T = any>(
    api: ApiType,
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  post: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  put: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  get: <T = any>(path: string, params?: RequestMessage, headers?: any) => Promise<T>;
  patch: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  delete: <T = any>(
    path: string,
    msg?: RequestMessage,
    params?: RequestMessage,
    headers?: any,
  ) => Promise<T>;
  setToken: (token?: string, refreshToken?: string, remember?: boolean) => User | undefined;
  getToken: () => string | undefined;
  token?: string;
  refreshToken: () => Promise<any>;
};

export interface EnumDef {
  id: string;
  type: string;
  name: string;
  tags: string[];
}

export class Enumerations {
  connection: ConnectionApi;
  types: { [type: string]: { [id: string]: EnumDef } } = {};

  constructor(connection: ConnectionApi) {
    this.connection = connection;
  }

  // getAll = async (type: string) => {
  //   try {
  //     if (this.types[type] === undefined) {
  //       const md = await this.connection.get(`admin/masterdata/all/${type}`, {
  //         attributes: ['name', 'tags'],
  //       });
  //       if (md.data.length > 0) {
  //         this.types[type] = Object.fromEntries(md.data.map((d: any) => [d.id, d]));
  //       } else {
  //         return {} as { [id: number]: EnumDef };
  //       }
  //     }
  //     return this.types[type];
  //   } catch (e) {
  //     return {} as { [id: number]: EnumDef };
  //   }
  // };

  // getValue = async (type: string, id: string) => {
  //   if (id === undefined) {
  //     return '';
  //   }
  //   const value = this.types[type]?.[id];
  //   if (value) {
  //     return value;
  //   }
  //   try {
  //     const md = await this.connection.get('admin/masterdata', {
  //       id: [id],
  //       type,
  //       attributes: ['name', 'tags'],
  //     });
  //     if (md.data.length > 0) {
  //       if (!this.types[type]) {
  //         this.types[type] = {};
  //       }
  //       this.types[type][id] = md.data[0].name;
  //     } else {
  //       return `${id}`;
  //     }
  //     return this.types[type][id];
  //   } catch (e) {
  //     return `${id}`;
  //   }
  // };
  //
  // getValues = async (type: string, ids: number[]) => {
  //   const notFound = ids.filter((id) => this.types[type]?.[id] === undefined);
  //   if (notFound.length === 0) {
  //     return ids.map((id) => ({ id, name: this.types[type]?.[id] }));
  //   }
  //   try {
  //     const md = await this.connection.get('admin/masterdata', {
  //       id: ids,
  //       type,
  //       attributes: ['id', 'name'],
  //     });
  //     for (const d of md.data) {
  //       if (!this.types[type]) {
  //         this.types[type] = {};
  //       }
  //       this.types[type][d.id] = d.name;
  //     }
  //     return ids.map((id) => ({ id, name: this.types[type]?.[id] }));
  //   } catch (e) {
  //     return [];
  //   }
  // };
}

export type User = {
  userId: string;
  firstName: string;
  lastName: string;
  name: string;
  phone: string;
  status: string;
  picture?: string;
  social?: string;
  role: string[];
  email: string;
  eyeball?: boolean;
  fieldAgent?: boolean;
  agentId?: string;
  quick?: boolean;
  linkedInUrl?: string;
  permission?: any[];
  mobile?: string;
};

export type RecruiterCtx = {
  recruiterId: string;
  recruiter: Recruiter;
  recruiters: UserRecruiter[];
  permissions: RecruiterPermission[];
  loading: boolean;
  token: string;
  refreshToken: () => Promise<string>;
  hasPermission: (permission: RecruiterPermission) => boolean;
  states: ApplicationState[];
};

export type RecruiterCache = {
  cache: any;
  setCache: (cache: any) => void;
};

export type NotificationData = {
  notificationCount: number;
  notificationData: number;
  alertCount: number;
  alertData: number;
  applicationCount: number;
  applicationData: number;
  total: number;
};

const ApplicationContext = React.createContext<{
  connection?: ConnectionApi;
  user?: User;
  enums: Enumerations;
  events: events.EventEmitter;
  recruiterCtx?: RecruiterCtx;
  recruiterCache?: RecruiterCache;
  showUnderConstruction: () => void;
  notificationData: NotificationData;
  setNotificationData: (notificationData: NotificationData) => void;
}>({} as any);
const universalCookie = new UniversalCookie();
export const ApplicationProvider: FC<PropsWithChildren> = (props) => {
  const userGtm = useRef<string>('');
  const [token, setToken] = useState<string>(universalCookie.get(AUTH_TOKEN));
  const [recruiterCtx, setRecruiterCtx] = useState<RecruiterCtx>();
  const [recruiterCache, setRecruiterCache] = useState<any>({});
  // const [states, setStates] = useState<ApplicationState[]>([]);
  const history = useRouter();
  const { pathname, query, replace } = useRouter();
  const ctxRecruiterId = recruiterCtx?.recruiterId;

  const recruiterId = useMemo(() => {
    if (pathname.startsWith('/recruiter/[id]/')) {
      return query.id as string;
    }
    return undefined;
  }, [pathname, query]);

  const getUserInfo = useCallback((token?: string) => {
    if (token) {
      try {
        return jwtDecode(token) as User;
      } catch (e) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, []);

  const [user, setUser] = useState<User | undefined>(
    universalCookie.get(AUTH_TOKEN)
      ? (jwtDecode(universalCookie.get(AUTH_TOKEN)) as User)
      : undefined,
  );

  const updateToken = useCallback(
    (token?: string, refreshToken?: string, remember?: boolean): User | undefined => {
      if (token && refreshToken) {
        try {
          // const decoded: any = jwtDecode(token);
          const refreshTokenDecoded: any = jwtDecode(refreshToken);
          const options: CookieSetOptions = { path: '/', domain: config.cookieDomain };
          const options1: CookieSetOptions = { ...options };
          if (remember) {
            options1.expires = new Date(refreshTokenDecoded.exp * 1000);
            options.expires = new Date(refreshTokenDecoded.exp * 1000);
          }
          universalCookie.set(AUTH_TOKEN, token, options);
          universalCookie.set(REFRESH_TOKEN, refreshToken, options1);
          setToken(token);
          const u: any = getUserInfo(token);
          setUser(u);
          return u;
        } catch (e) {
          console.error(e);
        }
      } else if (token) {
        try {
          const decoded: any = jwtDecode(token);
          const options: CookieSetOptions = { path: '/', domain: config.cookieDomain };
          if (remember) {
            options.expires = new Date(decoded.exp * 1000);
          }
          universalCookie.set(AUTH_TOKEN, token, options);
          setToken(token);
          const u: any = getUserInfo(token);
          setUser(u);
          return u;
        } catch (e) {
          console.error(e);
        }
      } else {
        universalCookie.remove(AUTH_TOKEN, { path: '/', domain: config.cookieDomain });
        universalCookie.remove(REFRESH_TOKEN, { path: '/', domain: config.cookieDomain });
      }
      return undefined;
    },
    [getUserInfo],
  );
  const getToken = useCallback(() => {
    if (token) {
      return token;
    } else {
      return '';
    }
  }, [token]);

  // useEffect(() => {
  //   setUser(getUserInfo(token));
  // }, [getUserInfo, token]);

  const connection: ConnectionApi = useMemo(() => {
    const conn = new Connection(recruiterCtx?.token ?? token ?? '');
    const refreshToken = async () => {
      const res = await conn.post(`auth/refresh-token`);
      updateToken(res.token);
      return jwtDecode(res.token);
    };
    return {
      send: conn.send,
      post: conn.post,
      put: conn.put,
      get: conn.get,
      patch: conn.patch,
      delete: conn.delete,
      setToken: updateToken,
      refreshToken,
      token: conn.token,
      getToken,
    };
  }, [token, updateToken, getToken, recruiterCtx]);

  useEffect(() => {
    const timer = setInterval(async () => {
      const newRefereshToken = universalCookie.get(REFRESH_TOKEN);
      const newToken = universalCookie.get(AUTH_TOKEN);
      if (newRefereshToken) {
        if (token !== newToken && newToken) {
          const oldUser = user?.userId;
          const newUser = getUserInfo(newToken);
          setToken(newToken);
          setUser(newUser);
          if (oldUser !== newUser?.userId) {
            setRecruiterCtx(undefined);
          }
        }
      } else if (token && !newToken && !newRefereshToken) {
        setToken(newRefereshToken);
        setUser(getUserInfo(undefined));
        setRecruiterCtx(undefined);
        universalCookie.remove(AUTH_TOKEN, { path: '/', domain: config.cookieDomain });
        universalCookie.remove(REFRESH_TOKEN, { path: '/', domain: config.cookieDomain });
        history.replace('/login');
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [getUserInfo, history, token, user?.userId]);

  useEffect(() => {
    const conn = new Connection(token);
    if (!recruiterId) {
      setRecruiterCtx(undefined);
    } else if (ctxRecruiterId !== recruiterId) {
      if (!user) {
        localStorage.setItem('BackUrl', `/recruiter/${query.id}/profile`);
        // localStorage.setItem('BackQuery', '{}');
        console.log('AAAAAAA!!!!!!!!', query.email);
        if (query.email) {
          conn
            .get(`users/profile/${query.email}`)
            .then(() => {
              replace('/login');
            })
            .catch(() => replace('/sign-up'));
        } else {
          replace('/login');
        }
        return;
      } else {
        setRecruiterCtx({ loading: true } as any);
        const refreshRecruiterToken = async () => {
          conn
            .get(`recruiter/${recruiterId}/session`, { email: query.email })
            .then((res: any) => {
              const permissions = [...res.permissions];
              const ctx: RecruiterCtx = {
                loading: false,
                token: res.token,
                recruiter: res.recruiter,
                recruiters: res.recruiters,
                permissions: res.permissions,
                states: res.states,
                refreshToken: refreshRecruiterToken,
                recruiterId,
                hasPermission: (permission) => permissions.indexOf(permission) !== -1,
              };
              setRecruiterCtx(ctx);
              setRecruiterCache(res.cache ?? {});
              return res.token;
            })
            .catch(() => replace('/recruiter/switch-account'));
          return '';
        };
        refreshRecruiterToken().then();
      }
    }
  }, [recruiterId, replace, token, ctxRecruiterId, user, query.id, query.email]);
  const enums = useMemo(() => new Enumerations(connection), [connection]);
  const evts = useRef(new events.EventEmitter()).current;

  useEffect(() => {
    const update = (value: Recruiter) => {
      if (recruiterCtx) {
        const temp = [...recruiterCtx.recruiters];
        const index = temp.findIndex((v) => v.recruiterId === value.recruiterId);
        if (index !== -1) {
          temp[index] = { ...temp[index], name: value.name, logo: value.logo ?? '' };
          setRecruiterCtx({ ...recruiterCtx, recruiters: temp });
        }
      }
    };
    const create = (value: Recruiter) => {
      if (recruiterCtx && user) {
        const temp = [...recruiterCtx.recruiters];
        temp.push({
          recruiterId: value.recruiterId,
          name: value.recruiterId,
          logo: value.logo ?? '',
          createdUser: user?.userId,
          role: 'Owner',
          status: 'Active',
          createdUserName: `${user?.name}`,
          activity: {
            activityStatus: false,
            deactivatedate: undefined,
          },
        });
        setRecruiterCtx({ ...recruiterCtx, recruiters: temp });
      }
    };
    evts.on('UPDATE_RECRUITER_PROFILE', update);
    evts.on('CREATE_RECRUITER_PROFILE', create);
    return () => {
      evts.off('UPDATE_RECRUITER_PROFILE', update);
      evts.off('CREATE_RECRUITER_PROFILE', create);
    };
  }, [evts, recruiterCtx, user, user?.name]);
  const [construction, setConstruction] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<NotificationData>({
    notificationData: 0,
    applicationData: 0,
    applicationCount: 0,
    alertCount: 0,
    alertData: 0,
    notificationCount: 0,
    total: 0,
  });
  useEffect(() => {
    const cookies = new UniversalCookie();
    const authToken = cookies.get(AUTH_TOKEN);
    let userId = 'guest';
    if (authToken) {
      try {
        const user = jwtDecode<any>(authToken);
        userId = user.userId;
      } catch (e) {
        console.error('Auth token decode error');
      }
    }
    if (userGtm.current !== userId) {
      if (userGtm.current !== '' && userId === 'guest') {
        Utils.sendGtmCustomEvent(
          'signout',
          {},
          {
            userId: 'guest',
          },
        );
      } else {
        Utils.sendGtmEvent({
          userId: userId,
        });
      }
      userGtm.current = userId;
    }
  }, [user?.userId]);

  return (
    <CookiesProvider>
      <ApplicationContext.Provider
        value={{
          connection,
          user,
          enums,
          events: evts,
          recruiterCtx,
          recruiterCache: {
            cache: recruiterCache,
            setCache: (csh: any) => {
              setRecruiterCache({ ...recruiterCache, ...csh });
            },
          },
          showUnderConstruction: () => setConstruction(true),
          notificationData,
          setNotificationData,
        }}>
        {props.children}
      </ApplicationContext.Provider>
      <Modal footer={false} open={construction} onCancel={() => setConstruction(false)}>
        <Image
          className={Utils.mergeClasses('w-full', 'h-3/6')}
          style={{ height: '80%' }}
          src={UnderConstruction}
          alt={'Under Construction Image'}
        />
      </Modal>
    </CookiesProvider>
  );
};

export default ApplicationContext;

export const useUnderConstruction = () => {
  return useContext(ApplicationContext).showUnderConstruction;
};
