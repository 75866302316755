import React, { FC, PropsWithChildren, useContext, useState } from 'react';

export type AgentLayoutContextData = {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
};

export const AgentLayoutContext = React.createContext<AgentLayoutContextData>({} as any);

export const AgentLayoutContextProvider: FC<PropsWithChildren> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  return (
    <AgentLayoutContext.Provider value={{ collapsed, setCollapsed }}>
      {props.children}
    </AgentLayoutContext.Provider>
  );
};

export const useAgentLayoutContext = () => useContext(AgentLayoutContext);
