import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { JobPostResponseData, SendJobPost } from '../../models/JobPostModals';
import { useConnection, useEvents, useUserInfo } from '../../connection/Application';
import { Grid } from 'antd';
import { useRouter } from 'next/router';
import JobAlertModal, { JobAlertModalInstance } from './components/JobAlertModal';
import Utils from '../../utils/Utils';

export const SearchQueryFields = [
  'from',
  'size',
  'search',
  'sector',
  'jobCategory',
  'company',
  'location',
  'salary_type',
  'SalaryUp',
  'SalaryDown',
  'graduate',
  'recent',
  'posted',
  'jobType',
  'jobFunction',
];

export const SearchQueryArrFields = [
  'sector',
  'jobCategory',
  'company',
  'location',
  'jobType',
  'jobFunction',
];

export type SearchQuery = {
  from?: number;
  size?: number;
  search?: string;
  sector?: string[];
  jobCategory?: string[];
  company?: string[];
  location?: string[];
  country?: string[];
  salary_type?: string;
  SalaryUp?: number;
  SalaryDown?: number;
  graduate?: boolean;
  recent?: boolean;
  posted?: string;
  jobType?: string[];
  jobFunction?: string[];
  workplaceType?: string[];
  jobId?: string;
  results?: string;
  status?: string;
  receiveApplications?: string;
  sortJobsDesc?: boolean;
  experienceLevel?: string[];
  currency?: string;
  onlyCurrency?: boolean;
};

export const AlertFields = [
  'search',
  'sector',
  'jobCategory',
  'company',
  'location',
  'salary_type',
  'SalaryUp',
  'SalaryDown',
  'graduate',
  'jobType',
  'jobFunction',
];

export type SearchContextData = {
  type: string;
  setType: (type: string) => void;
  results?: JobPostResponseData;
  setResults: (results?: JobPostResponseData) => void;
  selected?: SendJobPost;
  setSelected: (selected?: SendJobPost) => void;
  query: SearchQuery;
  setQuery: (query: SearchQuery) => void;
  search: (searchQuery?: SearchQuery, reset?: boolean) => void;
  reset: () => void;
  getCount: (searchQuery?: SearchQuery) => Promise<number>;
  loading: boolean;
  setAlert: (hasAlert: boolean) => void;
  alertEnable: boolean;
};

export const SearchContext = React.createContext<SearchContextData>({} as any);

export type Props = {
  query: SearchQuery;
  results: JobPostResponseData;
  navigationEnable?: boolean;
  resetQuery?: boolean;
};

const updateSearchHistory = (search: string) => {
  const recentSearch: string[] = JSON.parse(localStorage?.getItem('recentSearch') ?? '[]');
  const updatedSearch = recentSearch
    .filter((s) => s.toLowerCase() !== search.toLowerCase())
    .reverse()
    .slice(-6);
  updatedSearch.push(search);
  localStorage.setItem('recentSearch', JSON.stringify(updatedSearch.reverse()));
};

export const SearchContextProvider: FC<PropsWithChildren<Props>> = (props) => {
  const [type, setType] = useState<string>('jobs');
  const [results, setResults] = useState<JobPostResponseData | undefined>(props.results);
  const bp = Grid.useBreakpoint();
  const router = useRouter();
  const selectedJobPost = useMemo(() => {
    if (router.query.id === undefined || router.query.id.length < 2) {
      return undefined;
    }
    const idx = props.results.jobPost.findIndex((v) => v.jobPostId === router.query.id?.[1]);
    if (idx === -1) {
      return undefined;
    }
    return props.results.jobPost[idx];
  }, [props.results, router.query.id]);
  const [selected, setSelectedX] = useState<SendJobPost | undefined>(selectedJobPost);
  const [query, changeQuery] = useState<SearchQuery>(props.query ?? {});
  const [loading, setLoading] = useState<boolean>(false);
  const alertModel = useRef<JobAlertModalInstance>(null);
  const [alertEnable, setAlertEnable] = useState<boolean>(
    AlertFields.find((a) => ((props.query ?? {}) as any)[a] !== undefined) !== undefined,
  );
  const events = useEvents();
  const connection = useConnection();

  const user = useUserInfo();

  const setQuery = useCallback((q: SearchQuery) => {
    changeQuery(q);
    setAlertEnable(
      AlertFields.find((a) => (q as any)[a] !== undefined && (q as any)[a] !== '') !== undefined,
    );
  }, []);

  const setSelected = useCallback(
    (job?: SendJobPost, updatedQuery?: any) => {
      setSelectedX(job);
      if (job) {
        const newQuery: any = { ...updatedQuery };
        delete newQuery.jobId;
        if (props.navigationEnable !== false) {
          router.push(
            {
              pathname: `/jobs/${Utils.normalizeURIComponent(job.jobTitle ?? '')}/${job.jobPostId}`,
              query: newQuery,
            },
            undefined,
            {
              shallow: true,
            },
          );
        }
      } else {
        const newQuery: any = { ...updatedQuery };
        delete newQuery.job;
        delete newQuery.title;
        delete newQuery.jobId;
        if (props.navigationEnable !== false) {
          router.replace({ pathname: `/jobs`, query: newQuery }, undefined, {
            shallow: true,
          });
        }
      }
    },
    [props.navigationEnable, router],
  );

  useEffect(() => {
    if (query.search) {
      updateSearchHistory(query.search);
    }
  }, [query.search]);

  const search = useCallback(
    (searchQuery?: SearchQuery, reset?: boolean) => {
      if (searchQuery && searchQuery.from === undefined) {
        searchQuery.from = 0;
      }
      if (props?.query?.status && searchQuery && searchQuery.status === undefined) {
        searchQuery.status = props?.query?.status;
      }

      const newQuery: any = reset
        ? props?.resetQuery
          ? { results: 'show' }
          : { ...props.query }
        : { ...query };

      if (searchQuery) {
        for (const entry of Object.entries(searchQuery)) {
          newQuery[entry[0]] = entry[1];
        }
      }

      setQuery(newQuery);
      //router.push({ pathname: '/search', query: newQuery }, undefined, { shallow: true });
      setLoading(true);
      if (!bp.lg) {
        setSelected(undefined, newQuery);
      }
      connection
        .get('job-post', newQuery)
        .then((res) => {
          setResults(res);
          if (bp.lg) {
            setSelected(res.jobPost.length > 0 ? res.jobPost[0] : undefined, newQuery);
          } else {
            setSelected(undefined, newQuery);
          }
        })
        .finally(() => setLoading(false));
    },
    [bp.lg, connection, props.query, props?.resetQuery, query, setQuery, setSelected],
  );

  useEffect(() => {
    if (bp.lg && selected === undefined && (results?.jobPost ?? []).length > 0) {
      setSelected(results!.jobPost[0], query);
    }
  }, [bp.lg, query, results, selected, setSelected]);

  const handleApplied = useCallback(
    ({ jobPostId }) => {
      if (results) {
        const { aggs, jobPost, total, alertId } = results;
        setResults({
          aggs,
          alertId,
          total,
          jobPost: jobPost.map((j) => {
            if (j.jobPostId === jobPostId) {
              j.applied = j.receiveApplications === 'portal';
              j.appliedTime = Date.now();
              j.applicantCount = (j.applicantCount ?? 0) + 1;
            }
            return j;
          }),
        });
      }
    },
    [results],
  );
  const handleWithdrawn = useCallback(
    ({ jobPostId }) => {
      if (results) {
        const { aggs, jobPost, total, alertId } = results;
        setResults({
          aggs,
          alertId,
          total,
          jobPost: jobPost.map((j) => {
            if (j.jobPostId === jobPostId) {
              j.applied = false;
              j.appliedTime = undefined;
              j.applicantCount = (j.applicantCount ?? 1) - 1;
            }
            return j;
          }),
        });
      }
    },
    [results],
  );

  const handleSaved = useCallback(
    ({ jobPostId }) => {
      if (results) {
        const { aggs, jobPost, total, alertId } = results;
        setResults({
          aggs,
          alertId,
          total,
          jobPost: jobPost.map((j) => {
            if (j.jobPostId === jobPostId) {
              j.shortlisted = true;
            }
            return j;
          }),
        });
      }
    },
    [results],
  );

  const handleUnaved = useCallback(
    ({ jobPostId }) => {
      if (results) {
        const { aggs, jobPost, total, alertId } = results;
        setResults({
          aggs,
          alertId,
          total,
          jobPost: jobPost.map((j) => {
            if (j.jobPostId === jobPostId) {
              j.shortlisted = false;
            }
            return j;
          }),
        });
      }
    },
    [results],
  );

  useEffect(() => {
    events.on('JOB_APPLIED', handleApplied);
    events.on('JOB_WITHDRAWN', handleWithdrawn);
    events.on('JOB_SAVED', handleSaved);
    events.on('JOB_UNSAVED', handleUnaved);
    return () => {
      events.off('JOB_APPLIED', handleApplied);
      events.off('JOB_WITHDRAWN', handleWithdrawn);
      events.off('JOB_SAVED', handleSaved);
      events.off('JOB_UNSAVED', handleUnaved);
    };
  }, [events, handleApplied, handleSaved, handleUnaved, handleWithdrawn]);

  const getCount = useCallback(
    async (searchQuery?: SearchQuery) => {
      const newQuery: any = { ...query };
      if (searchQuery) {
        for (const entry of Object.entries(searchQuery)) {
          newQuery[entry[0]] = entry[1];
        }
      }
      const res = await connection.get('job-post', { ...newQuery, countOnly: true });
      return res.total;
    },
    [connection, query],
  );

  const setAlert = useCallback(
    (hasAlert: boolean) => {
      if (!results) {
        return;
      }
      if (!user) {
        localStorage.setItem('BackUrl', `/jobs`);
        localStorage.setItem('BackQuery', JSON.stringify(query));
        router.push('/login');
        return;
      }
      if (hasAlert) {
        alertModel.current?.show(query as any);
      } else {
        if (results.alertId) {
          connection.delete(`job-post/alerts/${results.alertId}`);
        }
        setResults({ ...results, alertId: undefined });
      }
    },
    [connection, query, results, router, user],
  );

  return (
    <SearchContext.Provider
      value={{
        type,
        setType,
        results,
        setResults,
        selected,
        setSelected: (sel) => setSelected(sel, query),
        query,
        setQuery,
        search,
        loading,
        reset: () => {
          search({}, true);
          events.emit('RESET_SEARCH');
          // changeQuery({});
        },
        getCount,
        setAlert,
        alertEnable,
      }}>
      {props.children}
      <JobAlertModal
        ref={alertModel}
        onAlertCreated={(alertId) => {
          if (results) {
            setResults({ ...results, alertId });
          }
        }}
      />
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
