import React, { FC, useCallback } from 'react';
import { Row } from 'antd';
import { LanguageEditor, LanguageSkill } from './LanguageEditor';
import { EnumInput } from '../../EnumInput/EnumInput';
import styles from './AdapterStyle.module.less';

type Props = {
  value?: LanguageData[];
  onChange?: (value: LanguageData[]) => void;
  staticLanguages: string[];
};

type LanguageData = {
  language: string;
  fluency?: LanguageSkill;
};

export const LanguageEditView: FC<Props> = (props) => {
  const addLanguage = useCallback(
    (value: string) => {
      props.onChange?.(
        [...(props.value ?? []), { language: value, fluency: 'Basic' } as any].filter(
          (f) => !!f.fluency,
        ),
      );
    },
    [props],
  );

  const handleLanguageChange = useCallback(
    (language: string, value?: LanguageSkill) => {
      const newLanguages = [...(props.value ?? [])];
      const index = newLanguages.findIndex((item) => item.language === language);
      if (index !== -1) {
        newLanguages[index].fluency = value;
      } else {
        newLanguages.push({ language, fluency: value });
      }

      props.onChange?.(newLanguages.filter((f) => !!f.fluency));
    },
    [props],
  );

  return (
    <>
      {/*<Row className="language-select-row">*/}
      {/*  <EnumInput enumName={'Language'} onChange={addLanguage as any} multiSelect={true} />*/}
      {/*</Row>*/}
      {props.staticLanguages.map((v, index) => (
        <div className={styles.langugeRow} key={index}>
          <LanguageEditor
            onchange={(value) => handleLanguageChange(v, value)}
            value={props.value?.find((item) => item.language === v)?.fluency}
            language={v}
          />
        </div>
      ))}
      {props.value
        ?.filter((item) => props.staticLanguages.findIndex((v) => v === item.language) === -1)
        .map((item, index) => (
          <div className={styles.langugeRow} key={index}>
            <LanguageEditor
              onchange={(value) => handleLanguageChange(item.language, value)}
              language={item.language}
              value={item.fluency ?? undefined}
            />
          </div>
        ))}
      <Row className={styles.fluencySelectContainer}>
        <EnumInput
          style={{ minWidth: '200px' }}
          value={'Add Language'}
          exclude={[
            ...props.staticLanguages,
            ...(props.value ?? [])
              .filter((item) => props.staticLanguages.findIndex((v) => v === item.language))
              .map((v) => v.language),
          ]}
          enumName={'Language'}
          onChange={(value) => addLanguage(value as string)}
          placeholder={'Add Language'}
        />
      </Row>
    </>
  );
};
