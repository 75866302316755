import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import stylesModule from './DateFilterInput.module.less';
import { Button, DatePicker, Dropdown, Radio } from 'antd';
import Utils from '../../utils/Utils';
import { Dayjs } from 'dayjs';

const styles: any = stylesModule;

export type DateFilterValue = {
  mode: 'equals' | 'between';
  dates: string[];
};

type DropdownProps = {
  value?: DateFilterValue;
  onChange?: (value?: DateFilterValue) => void;
};

type Props = DropdownProps & HTMLAttributes<HTMLDivElement>;

const DateFilterDropdown: FC<DropdownProps> = ({ value, onChange }) => {
  const [mode, setMode] = useState<'equals' | 'between'>('equals');
  const [date1, setDate1] = useState<Dayjs | null>(Utils.dayJs());
  const [date2, setDate2] = useState<Dayjs | null>(Utils.dayJs());
  useEffect(() => {
    if (!value) {
      setMode('equals');
      setDate1(Utils.dayJs());
      setDate2(Utils.dayJs());
    } else {
      setMode(value.mode ?? 'equals');
      setDate1(value.dates?.[0] ? Utils.dayJs(value.dates[0]) : Utils.dayJs());
      setDate2(value.dates?.[1] ? Utils.dayJs(value.dates[1]) : Utils.dayJs());
    }
  }, [value]);

  return (
    <div className="bg-white drop-shadow-md p-4 border border-slate-300 flex flex-col gap-2">
      <div>
        <Radio.Group onChange={(e) => setMode(e.target.value)} value={mode ?? 'equals'}>
          <Radio value={'equals'}>Created on</Radio>
          <Radio value={'between'}>Created between</Radio>
        </Radio.Group>
      </div>
      <div className="flex gap-2">
        <DatePicker
          value={date1}
          onChange={(date) => setDate1(date)}
          disabledDate={(current) =>
            mode === 'equals'
              ? current.valueOf() > Utils.moment().valueOf()
              : current.valueOf() > (date2 ?? Utils.moment()).valueOf() ||
                current.valueOf() > Utils.moment().valueOf()
          }
        />
        {mode === 'between' && (
          <DatePicker
            value={date2}
            onChange={(date) => setDate2(date)}
            disabledDate={(current) =>
              current.valueOf() <= (date1 ?? Utils.moment()).valueOf() ||
              current.valueOf() > Utils.moment().valueOf()
            }
          />
        )}
      </div>
      <div>
        <Button
          onClick={() =>
            onChange?.({
              mode,
              dates:
                mode === 'equals'
                  ? [(date1 ?? Utils.moment()).format('YYYY-MM-DD')]
                  : [
                      (date1 ?? Utils.moment()).format('YYYY-MM-DD'),
                      (date2 ?? Utils.moment()).format('YYYY-MM-DD'),
                    ],
            })
          }
          type="primary">
          Filter
        </Button>
      </div>
    </div>
  );
};

export const DateFilterInput: FC<Props> = (props) => {
  const { value } = props;
  const [visible, setVisible] = useState<boolean>(false);
  useEffect(() => setVisible(true), []);
  return (
    <Dropdown
      visible={visible}
      destroyPopupOnHide={true}
      onVisibleChange={(v) => {
        console.log('CHANGED FROM HERE', v);
        setVisible(v);
        props.onChange?.(props?.value);
      }}
      trigger={['click']}
      overlay={
        <DateFilterDropdown
          value={props.value}
          onChange={(val) => {
            setVisible(false);
            console.log('NO NO CHANGED FROM HERE');
            props.onChange?.(val);
          }}
        />
      }
      placement="bottomRight">
      <div
        {...props}
        onFocus={() => setVisible(true)}
        tabIndex={0}
        onKeyPress={(event) => {
          console.log('Key pressed', event.key, event.code);
          if (event.key === 'Space') {
            console.log('Space pressed');
          }
        }}
        role="textbox"
        className={styles.dateFilterInput}>
        {value && (value.mode === 'equals' ? value.dates[0] : value.dates.join(' - '))}
      </div>
    </Dropdown>
  );
};
