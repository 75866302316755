import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import styles from './HtmlTemplate.module.less';
import { HtmlTemplateView } from './HtmlTemplateView';
import { Button, Input } from 'antd';
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal';

const EmailEditorX: any = EmailEditor;

export type HtmlTemplate = {
  html: string;
  design: string;
  mergeTags?: string;
};
type Props = {
  value?: HtmlTemplate;
  onChange?: (value?: HtmlTemplate) => void;
  editorProps?: any;
  mergeTags?: boolean;
};
export const HtmlTemplateInput: FC<Props> = ({ editorProps, value, onChange, mergeTags }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const designer = useRef<any>(null);
  const editorLoaded = useRef<boolean>();
  const onSave = useCallback(() => {
    if (
      designer.current !== undefined &&
      designer.current !== null &&
      editorLoaded.current &&
      onChange
    ) {
      setSaving(true);
      designer.current.exportHtml((data: any) => {
        onChange({
          design: JSON.stringify(data.design),
          html: data.html,
          mergeTags: value?.mergeTags,
        });
        setSaving(false);
        setEditing(false);
      });
    }
  }, [onChange, value?.mergeTags]);
  const onEditorLoad = useCallback(() => {
    if (
      designer.current !== undefined &&
      designer.current !== null &&
      editorLoaded.current &&
      value
    ) {
      designer.current?.loadDesign?.(JSON.parse(value.design));
    }
  }, [value]);

  const mtgs = useMemo(() => {
    try {
      return mergeTags
        ? value?.mergeTags
          ? JSON.parse(value?.mergeTags)
          : undefined
        : editorProps?.options?.mergeTags;
    } catch (e) {
      return undefined;
    }
  }, [editorProps?.options?.mergeTags, mergeTags, value?.mergeTags]);
  return (
    <div className={styles.templateEditor}>
      {mergeTags && (
        <Input.TextArea
          value={value?.mergeTags}
          onChange={(e) =>
            onChange?.({ ...(value ?? { design: '', html: '' }), mergeTags: e.target.value })
          }
        />
      )}
      <div className={styles.templateEditorDisplay}>
        {value && <HtmlTemplateView value={value.html} />}
        <Button
          className={styles.templateEditorOverlay}
          type="ghost"
          onClick={() => setEditing(true)}>
          {' '}
        </Button>
      </div>
      <ResponsiveModal
        title={'Template'}
        open={editing}
        onOk={onSave}
        onCancel={() => setEditing(false)}
        okText="Save"
        confirmLoading={saving}
        cancelText="Cancel"
        destroyOnClose={true}
        width={'96%'}
        // maskClosable={this.props.maskClosable}
        closable={true}>
        <EmailEditorX
          style={{ width: '100%', minHeight: 'calc(100vh - 300px)' }}
          ref={(instance) => {
            (designer as any).current = instance;
            onEditorLoad();
          }}
          projectId={3657}
          onLoad={() => {
            editorLoaded.current = true;
            onEditorLoad();
          }}
          {...editorProps}
          options={{
            ...editorProps?.options,
            mergeTags: mtgs,
          }}
        />
      </ResponsiveModal>
    </div>
  );
};
