import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Input, Modal, Tooltip, Typography } from 'antd';
import Form, { FormSchema } from '../Form';
import { CheckCircleTwoTone, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useConnection } from '../../../connection/Application';

type Props = {
  value?: { phone?: string; visiblePhone?: boolean };
  onChange?: (value: { phone?: string; visiblePhone?: boolean }) => void;
};

const schema: FormSchema = [
  {
    layout: { md: 20, sm: 24 },
    fields: [
      {
        id: 'otp',
        type: 'string',
        name: 'OTP Code',
        props: { placeholder: 'OTP' },
        options: {
          rules: [
            { required: true, message: 'Please enter the verification code' },
            { max: 6, message: 'Only 6 characters are allowed.' },
            { pattern: new RegExp(/\d+/g), message: 'Only numbers are allowed' },
          ],
        },
      },
    ],
  },
];
const mobileSchema: FormSchema = [
  {
    layout: { md: 24, sm: 24 },
    fields: [
      {
        id: 'mobile',
        type: 'phone',
        name: 'Mobile',
        props: { placeholder: 'Phone Number', defaultCountry: 'LK' },
        options: {
          rules: [{ required: true, message: 'Please Enter Mobile Number.' }, { type: 'mobile' }],
        },
      },
    ],
  },
];

export const MobileInput: FC<Props> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [verifyVisible, setVerifyVisible] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingX, setLoadingX] = useState<boolean>(false);
  const [timeout, setTimeout] = useState<number>(0);
  // const [hiddenNumber, setHiddenNumber] = useState<boolean>(props.value?.hidden ?? false);
  const [visiblePhone, setVisiblePhone] = useState<boolean>(props.value?.visiblePhone ?? false);
  const connection = useConnection();
  const [form] = Form.useForm();
  const [formVerify] = Form.useForm();
  const { Text } = Typography;
  const sendOtp = useCallback(
    (data) => {
      setLoading(true);
      setVisible(true);
      console.log({ data });
      connection.post('user/send-otp', { mobile: data.mobile }).then((value) => {
        console.log(value.status);
        if (value.status === 'SUCCESS') {
          setPhone(data.mobile);
          setVisible(false);
          setVerifyVisible(true);
          setTimeout(90);
          formVerify.resetFields();
        } else {
          form.setFields([
            {
              name: 'mobile',
              errors: [value.code],
            },
          ]);
        }
        setLoading(false);
      });
    },
    [connection, form, formVerify],
  );

  useEffect(() => {
    // timeout > 0 && setInterval(() => setTimeout(timeout - 1), 1000);
    if (timeout > 0) {
      const timer = setInterval(() => setTimeout(timeout - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [timeout]);

  const verifyOtp = useCallback(
    (data) => {
      setLoadingX(true);
      connection
        .post('user/verify-otp', { mobile: phone, otp: data.otp })
        .then((response) => {
          if (response.status === 'FAILED') {
            formVerify.setFields([
              {
                name: 'otp',
                errors: [response.code],
              },
            ]);
          } else {
            setVerifyVisible(false);
            props.onChange?.({ phone, visiblePhone });
          }
        })
        .catch((e) =>
          formVerify.setFields([
            {
              name: 'otp',
              errors: [e.message],
            },
          ]),
        )
        .finally(() => setLoadingX(false));
    },
    [connection, phone, formVerify, props, visiblePhone],
  );
  return (
    <>
      <Modal
        visible={verifyVisible}
        title={'Mobile Number Verification'}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVerifyVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="resend"
            loading={loading}
            disabled={timeout !== 0}
            onClick={() => sendOtp({ mobile: phone })}>
            Resend
          </Button>,
          <Button
            key="verify"
            type="primary"
            loading={loadingX}
            onClick={() => formVerify.submit()}>
            Verify
          </Button>,
        ]}>
        <Text>Mobile Number : {phone}</Text>
        <div style={{ marginTop: 16 }}>
          <Form.Form form={formVerify} layout={'inline'} onFinish={verifyOtp}>
            <Form.Items schema={schema} />
          </Form.Form>
          {timeout !== 0 && (
            <Text style={{ color: 'red', marginTop: 16 }}>Your OTP Expires in : {timeout} S</Text>
          )}
        </div>
      </Modal>
      <Modal
        visible={visible}
        title={'Mobile Number Verification'}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key="send" type={'primary'} loading={loading} onClick={() => form.submit()}>
            Send OTP
          </Button>,
        ]}>
        <div>
          <Form.Form form={form} layout={'inline'} onFinish={sendOtp}>
            <Form.Items schema={mobileSchema} />
          </Form.Form>
        </div>
      </Modal>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={() => setVisible(true)}>
          <Input
            value={props.value?.phone}
            suffix={props.value?.phone ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : undefined}
            readOnly
          />
        </div>
        {props.value?.phone && (
          <Tooltip title={visiblePhone ? 'Visible to Recruiters' : 'Not visible to Recruiters'}>
            <Button
              type={'text'}
              onClick={() => {
                const temp = !visiblePhone;
                setVisiblePhone(temp);
                props.onChange?.({ phone: props.value?.phone, visiblePhone: temp });
              }}
              icon={visiblePhone ? <EyeOutlined /> : <EyeInvisibleOutlined color={'red'} />}
            />
          </Tooltip>
        )}
      </div>
    </>
  );
};
