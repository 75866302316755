import React, { FC, useEffect, useMemo, useState } from 'react';
import { Slider as AntdSlider } from 'antd';
import { ContentBlock } from '../../../uicomp/atmos/content-block/ContentBlock';

export const ExperienceRangeSlider: FC<any> = (props) => {
  const [value, setValue] = useState<number[] | undefined>(props.value);
  useEffect(() => setValue(props.value), [props.value]);

  const leftText = useMemo(() => {
    if (value && Array.isArray(value) && value.length === 2) {
      return `${value[0]} ${value[0] === 0 || value[0] === 1 ? 'Year' : 'Years'} `;
    } else {
      return '0 Year';
    }
  }, [value]);

  const rightText = useMemo(() => {
    if (value && Array.isArray(value) && value.length === 2) {
      return `${value[1]} ${value[1] === 0 || value[1] === 1 ? 'Year' : 'Years'} `;
    } else {
      return '50 Years';
    }
  }, [value]);

  return (
    <>
      <AntdSlider {...props} value={value} onChange={setValue} onAfterChange={props.onChange} />
      <ContentBlock style={{ fontSize: '0.9em' }} left={leftText} right={rightText} />
    </>
  );
};
