import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import superagent from 'superagent';
import Config from '../config';
import _ from 'lodash';

export type MasterDataObj = {
  id: string;
  value: string;
  inactive?: boolean;
  status?: string;
  [key: string]: any;
};
export type CacheContextData = {
  masterdata: Record<string, MasterDataObj[]>;
  masterdataById: Record<string, Record<string, MasterDataObj>>;
  mainType: Record<string, string>;
  enumValue: Record<string, Record<string, string>>;
  activeEnumValue: Record<string, Record<string, string>>;
  getLocationString: (locations: string[]) => string;
};

const CacheContext = React.createContext<CacheContextData>({} as any);
export default CacheContext;

const fillLocationWithCountry = (md: Record<string, MasterDataObj[]>) => {
  const locations = md[md[1].find((f) => f.value === 'Location')!.id];
  const countries = md[md[1].find((f) => f.value === 'Country')!.id];
  const sriLanka = countries.find((c) => c.code === 'LK')!.id;
  const countryById = Object.fromEntries(countries.map((c) => [c.id, c.value]));
  const combined = _.orderBy(
    [
      ...locations.map((l) => ({
        ...l,
        id: `l-${l.id}`,
        value: `${l.value}, ${countryById[l.country ?? sriLanka]}`,
      })),
      ...countries.map((c) => ({ ...c, id: `c-${c.id}` })),
    ],
    ['value'],
    ['asc'],
  );
  md['1'].push({ id: 'LocationWithCountries', value: 'LocationWithCountries' });
  md['LocationWithCountries'] = combined;
  return md;
};

export const CacheContextProvider: FC<any> = (props) => {
  const [masterdata, setMasterdata] = useState<Record<string, MasterDataObj[]>>({});
  const [masterdataById, setMasterdataById] = useState<
    Record<string, Record<string, MasterDataObj>>
  >({});
  useEffect(() => {
    superagent
      .get(`${Config.fileStoreUrl}/files/masterdata/data.json`)
      .send()
      .then((value) => {
        console.log('V Body', value);
        // setMasterdata({ ...value.body, ...StaticEnums });
        const md: Record<string, MasterDataObj[]> = { ...value.body };
        console.log('GRRRL');
        fillLocationWithCountry(md);
        const mdById = Object.fromEntries(
          Object.entries(md).map((o) => [o[0], Object.fromEntries(o[1].map((p) => [p.id, p]))]),
        );
        console.log('GRRRB');
        setMasterdata(md);
        setMasterdataById(mdById);
      })
      .catch((e) => console.error(`Store error: ${JSON.stringify(e)}`));
  }, []);
  const value = useMemo(() => {
    const mainType = Object.fromEntries((masterdata['1'] ?? []).map((v) => [v.value, v.id]));
    const mainTypes = Object.fromEntries((masterdata['1'] ?? []).map((v) => [v.id, v.value]));

    const enumValue: Record<string, Record<string, string>> = Object.fromEntries(
      [...Object.entries(masterdata)].map((v) => [
        mainTypes[v[0]],
        Object.fromEntries(v[1].map((c) => [c.id, c.value])),
      ]),
    );

    const activeEnumValue: Record<string, Record<string, string>> = Object.fromEntries(
      [...Object.entries(masterdata)].map((v) => [
        mainTypes[v[0]],
        Object.fromEntries(v[1].filter((v) => v.status === 'Active').map((c) => [c.id, c.value])),
      ]),
    );

    const getLocationString = (locations: string[]): string => {
      const locationType = mainType['Location'];
      const countryType = mainType['Country'];
      if (
        locationType === undefined ||
        countryType === undefined ||
        masterdataById[countryType] === undefined ||
        masterdataById[locationType] === undefined
      ) {
        return '';
      }
      const countries: { id: string; name: string; locations: string[] }[] = locations
        .map((l) => l.split('-'))
        .filter((l) => l[0] === 'c')
        .map((l) => l[1])
        .filter((l) => !!l)
        .map((l) => ({ id: l, name: masterdataById[countryType][l]?.value ?? '', locations: [] }));
      locations
        .map((l) => l.split('-'))
        .filter((l) => l[0] === 'l')
        .map((l) => l[1])
        .map((l) => masterdataById[locationType][l])
        .filter((l) => !!l)
        .forEach((l) => {
          let country = countries.find((c) => c.id === l.country);
          if (!country) {
            country = {
              id: l.country,
              name: masterdataById[countryType][l.country]?.value ?? '',
              locations: [],
            };
            countries.push(country);
          }
          country.locations.push(l.value ?? '');
        });
      countries.forEach((c) => (c.locations = c.locations.sort()));
      countries.sort((a, b) => {
        if (a.locations.length > 0 && b.locations.length === 0) {
          return -1;
        } else if (a.locations.length === 0 && b.locations.length > 0) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
      return countries
        .map((c) =>
          c.locations.length > 0 ? [c.locations.join(', '), c.name].join(' - ') : c.name,
        )
        .join(', ');
    };

    return {
      masterdata,
      masterdataById,
      mainType,
      enumValue,
      activeEnumValue,
      getLocationString,
    };
  }, [masterdata, masterdataById]);
  return <CacheContext.Provider value={value}>{props.children}</CacheContext.Provider>;
};

export const useCachedData = () => {
  return useContext(CacheContext);
};
export const EnumValue: FC<{ type: string; value: string }> = (props) => {
  const cache = useCachedData();
  return <>{cache.enumValue[props.type][props.value]}</>;
};
