import React, { CSSProperties, FC, useCallback } from 'react';
import { Input, InputProps } from 'antd';
import {
  DribbbleOutlined,
  DropboxOutlined,
  FacebookOutlined,
  GithubOutlined,
  GitlabOutlined,
  GlobalOutlined,
  GoogleOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MediumOutlined,
  RedditOutlined,
  SketchOutlined,
  SkypeOutlined,
  SlackOutlined,
  TwitterOutlined,
  YahooOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';

const hostIcons: Record<string, React.ComponentType> = {
  'github.com': GithubOutlined,
  'twitter.com': TwitterOutlined,
  'youtube.com': YoutubeOutlined,
  'skype.com': SkypeOutlined,
  'gitlab.com': GitlabOutlined,
  'medium.com': MediumOutlined,
  'linkedin.com': LinkedinOutlined,
  'google.com': GoogleOutlined,
  'dropbox.com': DropboxOutlined,
  'facebook.com': FacebookOutlined,
  'slack.com': SlackOutlined,
  'dribble.com': DribbbleOutlined,
  'instagram.com': InstagramOutlined,
  'yahoo.com': YahooOutlined,
  'reddit.com': RedditOutlined,
  'sketch.com': SketchOutlined,
};

const hostNames: Record<string, string> = {
  'github.com': 'Github',
  'twitter.com': 'Twitter',
  'youtube.com': 'Youtube',
  'skype.com': 'Skype',
  'gitlab.com': 'Gitlab',
  'medium.com': 'Medium',
  'linkedin.com': 'Linkedin',
  'google.com': 'Google',
  'dropbox.com': 'Dropbox',
  'facebook.com': 'Facebook',
  'slack.com': 'Slack',
  'dribble.com': 'Dribbble',
  'instagram.com': 'Instagram',
  'yahoo.com': 'Yahoo',
  'reddit.com': 'Reddit',
  'sketch.com': 'Sketch',
};

export const UrlIcon: FC<{ value?: string; style?: CSSProperties; linkedin?: boolean }> = (
  props,
) => {
  const { linkedin, value, style } = props;
  if (!value) {
    return <GlobalOutlined style={style} />;
  }
  try {
    if (linkedin) {
      return <LinkedinOutlined style={style} />;
    } else {
      let str = value as string;
      if (str.split(':').length === 1) {
        str = `https://${value}`;
      }
      const url = new URL(str);
      const host = url.host
        .split('.')
        .reverse()
        .filter((_, i) => i < 2)
        .reverse()
        .join('.');
      const Icon: any = hostIcons[host] ?? GlobalOutlined;
      return <Icon style={style} />;
    }
  } catch (e) {
    return <GlobalOutlined style={style} />;
  }
};

export const UrlText: FC<{ value?: string; style?: CSSProperties }> = (props) => {
  const { value, style } = props;
  if (!value) {
    return <GlobalOutlined style={style} />;
  }
  try {
    let str = value as string;
    if (str.split(':').length === 1) {
      str = `https://${value}`;
    }
    const url = new URL(str);
    const host = url.host
      .split('.')
      .reverse()
      .filter((_, i) => i < 2)
      .reverse()
      .join('.');
    return <>{hostNames[host] ?? 'Website'}</>;
  } catch (e) {
    return <>Website</>;
  }
};

export const UrlInput: FC<InputProps> = (props) => {
  const { value, onBlur, onChange } = props;

  const handleBlur = useCallback(
    (event: any) => {
      if (!value) {
        onBlur?.(event);
        return;
      }
      const str = value as string;
      if (str.split(':').length === 1) {
        event.target.value = `https://${value}`;
        onChange?.(event);
      }
      onBlur?.(event);
    },
    [onBlur, onChange, value],
  );
  return (
    <Input
      {...props}
      prefix={
        <UrlIcon
          value={value ? (value as string) : undefined}
          style={{ fontSize: '1.5em', marginRight: 10 }}
        />
      }
      onBlur={handleBlur}
    />
  );
};
