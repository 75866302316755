import React, { FC, useCallback, useEffect, useState } from 'react';
import { JobAlertManage, JobAlertManageProps } from './JobAlertManage';
import { useConnection } from '../../connection/Application';
import { Empty, message, Pagination, Spin } from 'antd';
import styles from './dashboard.module.less';

export const AlertManagementView: FC = () => {
  const [jobAlerts, setJobAlerts] = useState<JobAlertManageProps[]>([]);
  const [alertManagePage, setAlertManagePage] = useState<number>(1);
  const [alertTotal, setAlertTotal] = useState<number>(0);
  const [alertLoading, setAlertLoading] = useState<boolean>(false);
  const connection = useConnection();
  useEffect(() => {
    setAlertLoading(true);
    connection
      .get('job-alert', { page: alertManagePage })
      .then((response) => {
        setJobAlerts(response.alertData);
        setAlertTotal(response.total);
      })
      .catch(() => console.log('Error in getting Job alerts'))
      .finally(() => setAlertLoading(false));
  }, [alertManagePage, connection]);

  const editFrequency = useCallback(
    (data) => {
      connection
        .patch('job-alert/edit-frequency', data)
        .then((response) => {
          const alerts = [...jobAlerts];
          const index = alerts.findIndex((v) => v.alertId === data.alertId);
          alerts[index].frequency = response.frequency;
          setJobAlerts(alerts);
        })
        .catch(() => {
          message.error('Sorry!. Alert Not Updated');
        });
    },
    [connection, jobAlerts],
  );

  const deleteAlert = useCallback(
    (data) => {
      const alerts = jobAlerts.filter((item) => item.alertId !== data.alertId);
      setJobAlerts(alerts);
      connection
        .delete('job-alert/delete', data)
        .then(() => {
          console.log('Alert Deleted');
        })
        .catch(() => console.log('Alert Not Updated'));
    },
    [connection, jobAlerts],
  );

  return (
    <div className={styles.alertManagement}>
      <Spin spinning={alertLoading}>
        {jobAlerts?.length === 0 && <Empty description={'No alerts available'} />}
        <JobAlertManage
          data={jobAlerts}
          loading={alertLoading}
          onChange={(v) => editFrequency(v)}
          onDelete={(v) => deleteAlert(v)}
        />
      </Spin>
      {jobAlerts?.length > 0 && (
        <Pagination
          className={styles.pagination}
          current={alertManagePage}
          onChange={(page) => setAlertManagePage(page)}
          total={alertTotal}
        />
      )}
    </div>
  );
};
