/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Input } from 'antd';
import moment from 'moment';
import stylesModule from './AdapterStyle.module.less';
import dayjs from 'dayjs';
import { AnyMxRecord } from 'dns';
import Form, { FormSchema } from '../Form';
import { PhoneInput } from '../../PhoneInput/PhoneInput';

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
  presentText?: string;
  posttext?: string;
  checkboxId?: string;
  labelId?: string;
  placeholder?: string;
  checkboxvalue?: any;
  initialvalue?: any;
};

const styles: any = stylesModule;

export const ContactPersonName: FC<Props> = (props) => {
  const [value, setValue] = useState<string>(props.value ?? props.initialvalue);
  const [formVerify] = Form.useForm();

  useEffect(() => {
    if (props.initialvalue) {
      props.onChange?.(props.initialvalue);
    }
  }, [props.initialvalue]);

  const publishValueChange = useCallback(
    (val: any) => {
      setValue(val.target.value);

      props.onChange?.(val.target.value);
      // if (value !== val) {
      //   setValue(val);
      //   if (val === undefined) {
      //     props.onChange?.(undefined);
      //   } else {
      //     props.onChange?.(val);
      //   }
      // }
    },
    [props.onChange],
  );

  return (
    <>
      <div className="">
        <div className="flex flex-col">
          <div className="text-sm font-medium">Internal Contact</div>
          <div className="text-xs text-[#BFBFBF] font-normal mb-4">
            The contact information will be used for internal administrative purposes only and will
            not be publicly visible on your profile
          </div>
        </div>

        <Form.Item className="mb-0 w-[50%] pr-2" label="Contact Person's Name" required>
          <Input
            type="string"
            value={value}
            onChange={publishValueChange}
            placeholder={props.placeholder}
            className=" "
          />
        </Form.Item>
      </div>
    </>
  );
};
