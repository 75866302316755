import React, { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { MasterDataObj, useCachedData } from '../../connection/CacheContext';
import ReactCountryFlag from 'react-country-flag';

type Props = {
  enumName: string;
  value?: string;
  mode?: string;
  onChange?: (value: string | string[]) => void;
  placeholder?: string;
  exclude?: string[];
  size?: 'small' | 'middle' | 'large' | undefined;
  style?: React.CSSProperties;
  className?: string;
  prefix?: React.ReactNode;
  allowClear?: boolean;
  id?: string;
};
export const EnumInput: FC<Props> = ({
  value,
  enumName,
  onChange,
  placeholder,
  mode,
  exclude,
  allowClear,
  ...props
}) => {
  const { masterdata, mainType } = useCachedData();
  const [searchValue, setSearchValue] = useState<string>();
  const [options, setOptions] = useState<MasterDataObj[]>([]);
  useEffect(() => {
    console.log(JSON.stringify(masterdata['29'], null, 2));
    setOptions(
      (masterdata[mainType[enumName]] ?? []).filter((m) =>
        !m.inactive && exclude ? exclude.indexOf(m.id) === -1 : true,
      ),
    );
  }, [enumName, exclude, mainType, masterdata]);
  // const loadData = useCallback(() => {
  //   if (enumName) {
  //     setLoading(true);
  //     connection
  //       .get(`masterdata/${enumName}`)
  //       .then((res) => {
  //         setEnumData(res.masterdata);
  //       })
  //       .finally(() => setLoading(false));
  //   }
  // }, [connection, enumName]);
  // useEffect(() => {
  //   loadData();
  // }, [loadData]);
  // const optionsX = useMemo(() => {
  //   if (searchText) {
  //     return options.filter((v) => v.value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  //   }
  //   return options;
  // }, [options, searchText]);
  return (
    <div>
      <Select
        id={props.id}
        allowClear={allowClear}
        showSearch={true}
        filterOption={(inputValue, option) =>
          option?.children
            ? option.children.toString().toLowerCase().includes(inputValue.toLowerCase())
            : true
        }
        searchValue={searchValue}
        onSearch={setSearchValue}
        // filterSort={(optionA, optionB) => {
        //   const opt1 = `${optionA?.children ?? ''}`.toLowerCase();
        //   const opt2 = `${optionB?.children ?? ''}`.toLowerCase();
        //   if (searchValue) {
        //     const start1 = opt1.startsWith(searchValue.toLowerCase());
        //     const start2 = opt2.startsWith(searchValue.toLowerCase());
        //     if (start1 && !start2) {
        //       return -1;
        //     } else if (!start1 && start2) {
        //       return 1;
        //     }
        //   }
        //
        //   return opt1.localeCompare(opt2);
        // }}
        mode={mode as any}
        size={props.size}
        placeholder={placeholder}
        value={value}
        onChange={(value1) => {
          if (Array.isArray(value1)) {
            onChange?.(value1.filter((v) => v.trim() !== ''));
          } else {
            onChange?.(value1?.trim());
          }
          setSearchValue(undefined);
        }}
        style={{ display: 'block', width: '100%', ...props.style }}
        className={props.className}>
        {options.map((option) => {
          if (enumName === 'Country') {
            return (
              <Select.Option key={option.id} value={option.id}>
                <ReactCountryFlag
                  style={{ height: 21, width: 28, marginRight: 8, border: '1px solid #aaa' }}
                  countryCode={(option as any).code}
                  svg
                />
                {option.value}
              </Select.Option>
            );
          }
          return (
            <Select.Option key={option.id} value={option.id}>
              {option.value}
            </Select.Option>
          );
        })}
      </Select>
      {props.prefix !== undefined && props.prefix}
    </div>
  );
};
