import React, { FC, useCallback, useState } from 'react';
import _ from 'lodash';
import { ConnectionApi } from '../../connection/ApplicationContext';
import { useConnection } from '../../connection/Application';
import { UserProfile } from '../../models/UserModels';
import { Avatar, Select, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { TextBlock } from '../../uicomp/atmos/content-block/ContentBlock';

type Props = {
  onChange?: (value?: any) => void;
  value?: any;
};
const requestId = { value: 0 };
const fetchAutocompleteSuggestions = _.debounce(
  (
    connection: ConnectionApi,
    search: string,
    setValues: (values: UserProfile[]) => void,
    setFetching: (fetching: boolean) => void,
  ) => {
    setFetching(true);
    requestId.value += 1;
    const reqId = requestId.value;
    connection
      .get(`users`, { search, size: 7 })
      .then((value: any) => {
        setValues(value.users);
      })
      .finally(() => {
        if (reqId === requestId.value) {
          setFetching(false);
        }
      });
  },
  500,
);
export const SelectUser: FC<Props> = (props) => {
  const { onChange } = props;
  const [values, setValues] = useState<UserProfile[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const connection = useConnection();
  const fetchSuggestion = useCallback(
    (search: string) => {
      fetchAutocompleteSuggestions(connection, search, setValues, setFetching);
    },
    [connection],
  );
  const handlerChanged = useCallback(
    (value: any) => {
      onChange?.(value);
    },
    [onChange],
  );
  console.log(fetching);
  return (
    <Select
      {...props}
      showSearch
      onChange={handlerChanged}
      onSearch={fetchSuggestion}
      filterOption={false}
      size={'large'}
      // notFoundContent={fetching ? <Spin size="small" /> : null}
      style={{ width: '100%' }}>
      {values?.map((item) => {
        return (
          <Select.Option key={item.userId} value={item.userId}>
            <Space>
              <Avatar
                size={30}
                icon={item?.picture ? undefined : <UserOutlined />}
                src={item?.picture}
              />
              <Space>
                <TextBlock level={10}>{item.name}</TextBlock>
                <TextBlock level={10}>({item.email})</TextBlock>
              </Space>
            </Space>
          </Select.Option>
        );
      })}
    </Select>
  );
};
