import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AutoComplete, Select, Spin } from 'antd';
import { useConnection } from '../../connection/Application';
import _ from 'lodash';
import { ConnectionApi } from '../../connection/ApplicationContext';
import { useCachedData } from '../../connection/CacheContext';

export type AutocompleData = {
  id: string;
  value: string;
};
type Props = {
  onChange?: (value?: AutocompleData | AutocompleData[]) => void;
  value?: AutocompleData | AutocompleData[];
  type: string;
  inputMode?: 'suggest' | 'select' | 'multiple' | 'tags';
  category?: string;
  size?: any;
  prefix?: any;
  loadAll?: boolean;
  recruiterId?: string;
};

const requestId = { value: 0 };

const fetchAutocompleteSuggestions = _.debounce(
  (
    connection: ConnectionApi,
    type: string,
    search: string,
    setValues: (values: AutocompleData[]) => void,
    setFetching: (fetching: boolean) => void,
    recruiterId?: string,
  ) => {
    setFetching(true);
    requestId.value += 1;
    const reqId = requestId.value;
    if (type === 'recruiter') {
      connection
        .get(`autocomplete/recruiter/all`, { search })
        .then((value: any) => {
          setValues(value.masterdata);
        })
        .finally(() => {
          if (reqId === requestId.value) {
            setFetching(false);
          }
        });
    } else if (recruiterId) {
      connection
        .get(`eyeball/autocomplete/data/${recruiterId}`)
        .then((value: any) => {
          console.log('value.masterdata,', value);

          setValues(value);
        })
        .finally(() => {
          if (reqId === requestId.value) {
            setFetching(false);
          }
        });
    } else {
      connection
        .get(`autocomplete/data`, { search, type: [type] })
        .then((value: any) => {
          console.log('value.masterdata,', value.masterdata);

          setValues(value.masterdata);
        })
        .finally(() => {
          if (reqId === requestId.value) {
            setFetching(false);
          }
        });
    }
  },
  500,
);

export const AutoCompleteInput: FC<Props> = (props) => {
  const { inputMode, onChange, type } = props;
  const [values, setValues] = useState<AutocompleData[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const connection = useConnection();
  const { mainType, enumValue, masterdata } = useCachedData();
  const reverseEnums = useMemo(() => {
    const val: Record<string, string> = {};
    if (enumValue[type]) {
      Object.entries(enumValue[type]).forEach((e) => (val[e[1]] = e[0]));
    }
    return val;
  }, [enumValue, type]);
  const handlerChanged = useCallback(
    (value: any) => {
      if (Array.isArray(value)) {
        const x = value
          .map((v) => {
            // console.log('aaaaaa', enumValue[type][v]);
            console.log('bbbbbb', v);
            if (reverseEnums[v.trim()]) {
              return { id: reverseEnums[v], value: v.trim() };
            }
            return { id: 'NEW', value: v.trim() };
          })
          .filter((c) => c.value !== '');
        onChange?.(x);
      } else {
        console.log('vcccc', value);
        if (enumValue[type] && enumValue[type][value]) {
          onChange?.({ id: value, value: enumValue[type][value] });
        }
        onChange?.({ id: 'NEW', value });
      }
    },
    [enumValue, onChange, reverseEnums, type],
  );
  const fetchSuggestion = useCallback(
    (search: string) => {
      fetchAutocompleteSuggestions(
        connection,
        mainType[type],
        search,
        setValues,
        setFetching,
        props.recruiterId,
      );
    },
    [connection, mainType, props.recruiterId, type],
  );

  useEffect(() => {
    if (props.loadAll) {
      setValues(masterdata[type]);
    }
  }, [masterdata, props.loadAll, type]);
  if (inputMode && inputMode !== 'suggest') {
    return (
      <>
        <Select
          {...props}
          showSearch={!props.loadAll}
          size={props.size}
          mode={inputMode === 'select' ? undefined : inputMode}
          onChange={handlerChanged}
          onSearch={props.loadAll ? undefined : fetchSuggestion}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          style={{ width: '100%' }}>
          {values?.map((item) => {
            return (
              <Select.Option key={item.id} value={item.value}>
                {item.value}
              </Select.Option>
            );
          })}
        </Select>
      </>
    );
  }
  return (
    <AutoComplete
      {...props}
      showSearch
      onChange={handlerChanged}
      onSearch={fetchSuggestion}
      size={props.size}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      style={{ width: '100%' }}>
      {values?.map((item) => {
        return (
          <AutoComplete.Option key={item.id} value={item.value}>
            {item.value}
          </AutoComplete.Option>
        );
      })}
    </AutoComplete>
  );
};
