/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Switch } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import styles from './AdapterStyle.module.less';
import { ApproachVia, ApproachViaSwitch } from './ApproachViaSwitch';
import { useConnection } from '../../../connection/Application';

type Props = {
  whatsApp?: any;
  autoenable?: boolean;
  mobile?: any;
  value?: { eyeball: boolean; approachVia: ApproachVia; EyeballActionby: 'Admin' | 'User' };
  onChange?: (value?: {
    eyeball: boolean;
    approachVia?: ApproachVia;
    EyeballActionby: 'Admin' | 'User';
  }) => void;
};

export const EyeBallSwitch: FC<Props> = ({ value, onChange, mobile, whatsApp, autoenable }) => {
  const eyeBallOnText =
    'Open to eyeballing. Recruiters will see your profile in their searches and may approach you.';
  const eyeBallOffText =
    mobile === undefined
      ? 'Please enter the mobile number to enable the eyeballing feature'
      : "You're closed to eyeballing. Recruiters won't be able to discover you and come up to you with interesting opportunities";
  const onValueChange = useCallback(
    (v) => {
      onChange?.({ eyeball: v, approachVia: value?.approachVia, EyeballActionby: 'User' });
    },
    [onChange, value?.approachVia],
  );
  const onApproachChange = useCallback(
    (x) => {
      onChange?.({ eyeball: value?.eyeball ?? false, approachVia: x, EyeballActionby: 'User' });
    },
    [onChange, value?.eyeball],
  );
  const text = useMemo(() => {
    if (value?.eyeball) {
      return eyeBallOnText;
    }
    return eyeBallOffText;
  }, [value]);

  const mobilePhone = useMemo(() => mobile, [mobile]);

  const prevMobileRef = useRef();

  useEffect(() => {
    if (mobilePhone !== prevMobileRef.current) {
      if (mobile && !value?.eyeball && autoenable) {
        onChange?.({ eyeball: true, approachVia: value?.approachVia, EyeballActionby: 'User' });
      }
      if (!mobile) {
        onChange?.({ eyeball: false, approachVia: value?.approachVia, EyeballActionby: 'User' });
      }
      prevMobileRef.current = mobilePhone;
    }
  }, [mobilePhone, mobile, onChange, value?.approachVia, value?.eyeball]);

  return (
    <div className={styles.eyeball}>
      <div>
        <Switch
          disabled={mobilePhone === undefined}
          id={'profile-switch-eyeball'}
          onChange={onValueChange}
          checkedChildren={<EyeFilled size={60} />}
          unCheckedChildren={<EyeInvisibleFilled size={60} />}
          checked={mobile === undefined ? false : value?.eyeball}
        />
        {text && <div className={styles.eyeballText}>{text}</div>}
      </div>
      {value?.eyeball && (
        <div>
          <ApproachViaSwitch
            info={{ phone: mobile, whatsapp: whatsApp }}
            value={value?.approachVia}
            onChange={onApproachChange}
          />
        </div>
      )}
    </div>
  );
};
