import React, { FC, useState } from 'react';
import { Checkbox, Radio } from 'antd';
import styles from './AdapterStyle.module.less';
import { useCachedData } from '../../../connection/CacheContext';

export type LanguageSkill = 'Basic' | 'Intermediate' | 'Fluent';
type Props = {
  language: string;
  value?: LanguageSkill;
  onchange?: (value?: LanguageSkill) => void;
};

export const LanguageEditor: FC<Props> = (props) => {
  const { language, onchange, value } = props;
  const [level, setLevel] = useState<LanguageSkill>(props.value ?? 'Basic');
  const { enumValue } = useCachedData();
  return (
    <div className={styles.fluencySelectContainer}>
      <div className={styles.fluencyLanguage}>
        <Checkbox
          checked={props.value !== undefined}
          onChange={(checked) => {
            if (checked.target.checked) {
              onchange?.(level);
            } else {
              onchange?.(undefined);
            }
          }}>
          {enumValue?.['Language']?.[language]}
        </Checkbox>
      </div>
      <div className={styles.fluencySelector}>
        <Radio.Group
          size={'middle'}
          disabled={props.value === undefined}
          options={['Basic', 'Intermediate', 'Fluent']}
          onChange={(e) => {
            onchange?.(e.target.value);
            setLevel(e.target.value);
          }}
          value={value}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
    </div>
  );
};
