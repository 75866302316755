import React, { FC, useState } from 'react';
import { ContentBlock, TextBlock } from '../../uicomp/atmos/content-block/ContentBlock';
import styles from './dashboard.module.less';
import { Logo } from '../../uicomp/atmos/logo/Logo';
import { Popconfirm, Radio, Space } from 'antd';
import { BellOutlined, DeleteOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { useCachedData } from '../../connection/CacheContext';

export type JobAlertManageProps = {
  alertId: string;
  search: string;
  location?: string[];
  frequency: string;
  recruiter: any[];
  jobType: string[];
  recruiterImage?: any;
};

type Props = {
  data: JobAlertManageProps[];
  loading?: boolean;
  onChange?: (value: { alertId: string; frequency: string }) => void;
  onDelete?: (value: { alertId: string }) => void;
};

const JobAlertItem: FC<{
  item: JobAlertManageProps;
  onChange?: (value: string) => void;
  onDelete?: () => void;
}> = (props) => {
  const { alertId, frequency, recruiter, location, jobType, search, recruiterImage } = props.item;
  const [freq, setFreq] = useState<string>(frequency);
  const { enumValue } = useCachedData();
  return (
    <div className={styles.jobAlertItem}>
      <ContentBlock
        gap={'lg'}
        className={styles.jobCardTitleContainer}
        left={recruiter?.length === 1 ? <Logo src={recruiter[0].logo} /> : undefined}
        right={
          <Popconfirm
            placement="left"
            title={'Are you sure to delete this Alert?'}
            onConfirm={() => props.onDelete?.()}
            okText="Yes"
            cancelText="No">
            <DeleteOutlined className={styles.red} />
          </Popconfirm>
        }>
        <Space direction={'vertical'} className={'fullwidth'} size={'small'}>
          <TextBlock heading>{search}</TextBlock>
          <TextBlock level={1}>{recruiter?.map((v) => v.name).join(' / ')}</TextBlock>
          <TextBlock secondary>
            {Array.isArray(location)
              ? location?.map((v) => enumValue?.['Location']?.[v])?.join(' / ')
              : location
              ? enumValue['Location']?.[location]
              : ''}
          </TextBlock>
        </Space>
        <div className={styles.gap8}>
          <Space>
            <ContentBlock className={styles.contentLeftAlert} left={<BellOutlined />}>
              <TextBlock danger>{freq ?? 'Instant'}</TextBlock>
            </ContentBlock>
            {jobType && !!jobType.length && (
              <ContentBlock secondary left={<FontAwesomeIcon icon={faBriefcase} />}>
                {jobType.map((v) => enumValue?.['Job Types']?.[v]).join(' / ')}
              </ContentBlock>
            )}
          </Space>
        </div>
      </ContentBlock>
      <Space direction={'horizontal'} className={styles.radioGroup}>
        <Radio.Group
          onChange={(e) => {
            setFreq(e.target.value);
            props.onChange?.(e.target.value);
          }}
          value={freq}>
          <Radio value={'weekly'}>Weekly</Radio>
          <Radio value={'daily'}>Daily</Radio>
          <Radio value={'instant'}>Instant</Radio>
        </Radio.Group>
      </Space>
    </div>
  );
};

export const JobAlertManage: FC<Props> = (props) => {
  return (
    <div>
      {props.data.map((item) => {
        return (
          <JobAlertItem
            item={item}
            key={item.alertId}
            onChange={(v) => props.onChange?.({ alertId: item.alertId, frequency: v })}
            onDelete={() => props.onDelete?.({ alertId: item.alertId })}
          />
        );
      })}
    </div>
  );
};
