import React, { FC, useState } from 'react';
import styles from './ResumeUploader.module.less';
import { CloseOutlined, CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
type Props = {
  title: string;
  text?: string;
  type?: string;
  status?: boolean;
  onDownload?: () => void;
  onChange?: (value?: boolean) => void;
  onDelete?: () => void;
};
export const ResumeCard: FC<Props> = (props) => {
  const [checkbox, setCheckbox] = useState<boolean>(true);
  return (
    <div className={styles.fileHolder}>
      {props.onChange && (
        <div className="flex items-center pr-2">
          <Checkbox
            id={props.text}
            checked={props.status}
            onChange={(e) => {
              setCheckbox(e.target.checked);
              props.onChange && props.onChange(e.target.checked);
            }}></Checkbox>
        </div>
      )}

      <FilePdfOutlined className={styles.fileIcon} />
      <div className={styles.fileName}>
        <div className={styles.titleText}>{props.title}</div>
        <div className={styles.subText}>{props.text}</div>
      </div>
      <div className={styles.rowContent}>
        <CloudDownloadOutlined onClick={props.onDownload} className={styles.cloudIcon} />
        <div style={{ width: '9px' }} />
        {props.onDelete && <CloseOutlined onClick={props.onDelete} className={styles.closeIcon} />}
      </div>
    </div>
  );
};
