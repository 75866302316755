/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Input } from 'antd';
import moment from 'moment';
import stylesModule from './AdapterStyle.module.less';
import dayjs from 'dayjs';
import { AnyMxRecord } from 'dns';
import Form, { FormSchema } from '../Form';
import { PhoneInput } from '../../PhoneInput/PhoneInput';

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
  presentText?: string;
  posttext?: string;
  checkboxId?: string;
  labelId?: string;
  placeholder?: string;
  checkboxvalue?: any;
  initialvalue?: any;
};

const styles: any = stylesModule;

export const ContactPersonEmail: FC<Props> = (props) => {
  const [checkbox, setCheckkbox] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.value ?? props.initialvalue);
  const [formVerify] = Form.useForm();

  useEffect(() => {
    if (props?.initialvalue && !props?.value) {
      props.onChange?.(props.initialvalue);
    }
  }, [props.initialvalue]);

  useEffect(() => {
    if (value && value !== '') {
      setCheckkbox(value === props.checkboxvalue ? true : false);
    }
  }, [props.checkboxvalue]);

  const publishValueChange = useCallback(
    (val: any) => {
      setValue(val.target.value ?? undefined);
      props.onChange?.(val.target.value ?? undefined);
    },
    [props.onChange, value],
  );

  const handleCheckbox = useCallback((checked: boolean, checkboxvalue: string) => {
    setCheckkbox(checked);

    if (checked) {
      setValue(checkboxvalue ?? '');
      props.onChange?.(checkboxvalue ?? '');
    } else {
      setValue('');
      props.onChange?.('');
    }
  }, []);

  return (
    <>
      <div className="">
        <Input
          type="email"
          disabled={checkbox}
          value={value}
          onChange={publishValueChange}
          placeholder={props.placeholder}
          className=" "
        />
        <Checkbox
          disabled={props.checkboxvalue ? false : true}
          className="m-0"
          id={props.checkboxId ?? 'date_picker_checkbox'}
          style={{ maxWidth: props.presentText ? `${props.presentText.length + 3}ch` : '21ch' }}
          onChange={(e) => handleCheckbox(e.target.checked, props.checkboxvalue)}
          checked={checkbox}>
          <span className="m-0" id={props.labelId ?? 'date_picker_current_text'}>
            {props.presentText ?? "Same as Company's"}
          </span>
        </Checkbox>
      </div>
    </>
  );
};
