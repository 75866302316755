import React, { FC } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../config';
import { FlexBox } from '../FlexBox';

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
  align?: 'left' | 'center' | 'right';
};
const ReCapt: any = ReCAPTCHA;
export const GoogleRecaptcha: FC<Props> = ({ align, onChange }) => {
  return (
    <FlexBox horizontalAlign={align ?? 'center'}>
      <ReCapt
        sitekey={Config.recaptchaSiteKey}
        size="invisible"
        onChange={(token) => onChange?.(token !== null ? token : undefined)}
        onExpired={() => onChange?.(undefined)}
      />
    </FlexBox>
  );
};
