/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ReactPhoneInput, {
  DefaultInputComponentProps as PhoneInputProps,
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from 'react-phone-number-input';
import Styles from './PhoneInput.module.less';
import ReactCountryFlag from 'react-country-flag';
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal';
import { Button, message } from 'antd';
import Form, { FormSchema } from '../form/Form';
import { useConnection } from '../../connection/Application';
import Utils from '../../utils/Utils';

const styles: any = Styles;
const ReactPhoneInputX: any = ReactPhoneInput;

export const PhoneInputPrivate: FC<PhoneInputProps> = (props) => {
  return (
    <div className={styles.phoneInput}>
      <ReactPhoneInputX className={props.disabled ? styles.disabled : ''} {...props} />
    </div>
  );
};

const phoneSchema: FormSchema = [
  {
    id: 'phone',
    type: 'phone',
    name: 'Phone number',
    props: { placeholder: 'Phone Number', defaultCountry: 'LK' },
    options: {
      rules: [
        { required: true, message: 'Please enter the phone number.' },
        {
          validator: 'phone',
          message: 'Invalid Mobile Number',
        },
      ],
    },
  },
];

const schema: FormSchema = [
  {
    id: 'otp',
    type: 'string',
    name: 'Verification Code',
    props: { placeholder: 'OTP' },
    options: {
      rules: [
        { required: true, message: 'Please enter the verification code' },
        { max: 6, message: 'Only 6 characters are allowed.' },
        { pattern: new RegExp(/\d+/g), message: 'Only numbers are allowed' },
      ],
    },
  },
];

export const PhoneInputWithVerification: FC<PhoneInputProps> = (props) => {
  const countryCode = useMemo(() => {
    if (!props.value) {
      return props.defaultCountry;
    }
    return parsePhoneNumber(props.value)?.country;
  }, [props.defaultCountry, props.value]);
  const [visible, setVisible] = useState<boolean>(false);
  const [verifyVisible, setVerifyVisible] = useState<boolean>(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState<boolean>(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>();
  const [showTimeError, setShowTimeError] = useState<boolean>(false);
  const [timeout, setTimeout] = useState<number>(0);
  const [showResend, setShowResend] = useState<boolean>(false);
  const connection = useConnection();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const handlerSendOtp = useCallback(
    (resent: boolean, phone) => {
      (resent ? form : form2).resetFields();
      setPhoneValue(phone);
      setLoadingSendOtp(true);
      connection
        .post('profiles/verify', { phone })
        .then((res) => {
          if (res.verified) {
            message.success('Number already verified!');
            props.onChange?.(phone);
            setVisible(false);
            setVerifyVisible(false);
            setShowTimeError(false);
          } else if (res.alreadySent) {
            setShowTimeError(true);
          } else {
            setVisible(false);
            setVerifyVisible(true);
            setShowTimeError(false);
            setTimeout(90);
            setShowResend(true);
          }
        })
        .catch((e) => {
          (resent ? form : form2).setFields([
            { name: resent ? 'otp' : 'phone', errors: [e.message] },
          ]);
        })
        .finally(() => setLoadingSendOtp(false));
    },
    [connection, form, form2, props],
  );
  const handlerVerifyOtp = useCallback(
    (data) => {
      if (phoneValue) {
        setLoadingVerifyOtp(true);
        connection
          .post('profiles/verify/otp', { phone: phoneValue, otp: data.otp })
          .then(() => {
            message.success('Phone number verified');
            props.onChange?.(phoneValue);
            setVerifyVisible(false);
            form.resetFields();
          })
          .catch((e) => {
            form.setFields([{ name: 'otp', errors: [e.message] }]);
          })
          .finally(() => setLoadingVerifyOtp(false));
      }
    },
    [connection, form, phoneValue, props],
  );

  useEffect(() => {
    // timeout > 0 && setInterval(() => setTimeout(timeout - 1), 1000);
    if (timeout > 0) {
      const timer = setInterval(() => setTimeout(timeout - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [timeout]);

  const showModal = useCallback(() => {
    form2.resetFields();
    setVisible(true);
  }, [form2]);
  return (
    <>
      <div
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            showModal();
          }
        }}
        role="button"
        className={styles.phoneVerifyInput}
        onClick={showModal}>
        <ReactCountryFlag countryCode={countryCode} svg style={{ fontSize: '1.8em' }} />
        <div
          className={
            props.value ? styles.phoneVerifyInputText : styles.phoneVerifyInputPlaceholder
          }>
          {props.value ? formatPhoneNumberIntl(props.value) : props.placeholder}
        </div>
      </div>
      <ResponsiveModal
        style={{ maxWidth: '500px' }}
        title={'Send OTP Verify mobile number'}
        okText={'Send Verification Code'}
        visible={visible}
        maskClosable={loadingSendOtp}
        cancelButtonProps={{ disabled: loadingSendOtp }}
        okButtonProps={{ loading: loadingSendOtp }}
        onOk={form2.submit}
        destroyOnClose={true}
        closable={!loadingSendOtp}
        onCancel={() => setVisible(false)}>
        <Form.Form
          form={form2}
          layout={'vertical'}
          onFinish={(values) => handlerSendOtp(false, values.phone)}>
          <Form.Items schema={phoneSchema} />
        </Form.Form>
      </ResponsiveModal>
      <ResponsiveModal
        style={{ maxWidth: '500px' }}
        title={'Verify mobile number'}
        okText={'Verify'}
        visible={verifyVisible}
        maskClosable={loadingVerifyOtp}
        cancelButtonProps={{ disabled: loadingVerifyOtp }}
        okButtonProps={{
          loading: loadingVerifyOtp || loadingSendOtp,
          disabled: timeout === 0 && showResend,
        }}
        onOk={form.submit}
        closable={!loadingVerifyOtp}
        // footer={[
        //   <Button disabled={loadingVerifyOtp} key="cancel" onClick={() => setVerifyVisible(false)}>
        //     Cancel
        //   </Button>,
        //   // <Button
        //   //   key="Resend"
        //   //   disabled={loadingVerifyOtp && timeout !== 0}
        //   //   loading={loadingVerifyOtp || loadingSendOtp}
        //   //   onClick={() => handlerSendOtp(true, phoneValue)}>
        //   //   Resend
        //   // </Button>,
        //   <Button
        //     key="verify"
        //     type="primary"
        //     loading={loadingVerifyOtp || loadingSendOtp}
        //     disabled={timeout === 0 && showResend}
        //     onClick={form.submit}>
        //     Verify
        //   </Button>,
        // ]}
        onCancel={() => setVerifyVisible(false)}>
        <div
          style={{
            marginBottom: '10px',
          }}>
          {`Verification OTP has been sent to ${Utils.formatPhoneNumber(phoneValue ?? '')}.`}
          {showTimeError && <> Please wait 1 minute before resending.</>}
        </div>
        <Form.Form form={form} layout={'vertical'} onFinish={handlerVerifyOtp}>
          <Form.Items schema={schema} />
        </Form.Form>
        {timeout !== 0 && <span style={{ color: 'red' }}>Your OTP Expires in : {timeout} S</span>}
        {timeout === 0 && showResend && (
          <span style={{ color: 'red' }}>
            Verification Timeout
            <Button type={'link'} onClick={() => handlerSendOtp(true, phoneValue)}>
              Resend OTP
            </Button>
          </span>
        )}
      </ResponsiveModal>
    </>
  );
};

export const PhoneInput: FC<
  Omit<PhoneInputProps, 'value'> & { verify?: boolean; value?: string }
> = (props) => {
  if (props.verify) {
    return <PhoneInputWithVerification {...props} value={props.value ?? ''} />;
  }
  return <PhoneInputPrivate {...props} value={props.value ?? ''} />;
};
