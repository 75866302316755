import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import stylesModule from './SearchComponents.module.less';
import { AutoComplete, Button, Grid, Input, Select, Tabs } from 'antd';
import { HistoryOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { useSearchContext } from '../SearchContext';
import { useConnection, useEvents } from '../../../connection/Application';
import { ConnectionApi, useUnderConstruction } from '../../../connection/ApplicationContext';
import _ from 'lodash';
import { ContentBlock } from '../../../uicomp/atmos/content-block/ContentBlock';

const styles: any = stylesModule;

export type SearchInputValue = {
  search?: string;
};

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
  className?: string;
  triggerOnChange?: boolean;
  triggerOnEnter?: boolean;
  showButton?: boolean;
  prefix?: React.ReactNode;
  size?: 'small' | 'middle' | 'large' | undefined;
  placeholder?: string;
  getPopupContainer?: (props: any) => HTMLElement;
  hideJobSelect?: boolean;
  id?: string;
};
const renderTitle = (title: string) => (
  <span>
    {title}
    <a
      style={{ float: 'right' }}
      href="https://www.google.com/search?q=antd"
      target="_blank"
      rel="noopener noreferrer">
      more
    </a>
  </span>
);

const renderItem = (title: string, count: number) => ({
  value: title,
  label: (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      {title}
      <span>
        <UserOutlined /> {count}
      </span>
    </div>
  ),
});

export const searchDebounced = _.debounce(
  (connection: ConnectionApi, value: string, callback: (data: string[]) => void) => {
    connection
      .get('autocomplete/data', { type: 'Job Title', search: value })
      .then(({ masterdata }) => callback(masterdata.map((m: any) => m.value)));
  },
  300,
);

export const SearchAutocomplete: FC<Props> = ({ onChange, triggerOnEnter, ...props }) => {
  const [value, setValue] = useState<string | undefined>(props.value);
  const connection = useConnection();
  const lastSearch = useRef<number>(0);
  const [masterdata, setMasterdata] = useState<string[]>();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const bp = Grid.useBreakpoint();
  const events = useEvents();
  useEffect(() => {
    if (!props.triggerOnChange) {
      setDropdownVisible(false);
    }
  }, [props.triggerOnChange, props.value]);
  const options = useMemo(() => {
    if (typeof window !== 'undefined' && (!value || value.trim() === '')) {
      const recentSearch: string[] = JSON.parse(localStorage?.getItem('recentSearch') ?? '[]');
      if (recentSearch.length === 0) {
        return [];
      }
      return [
        {
          label: (
            <ContentBlock secondary level={-2} left={<HistoryOutlined />}>
              Recent searches
            </ContentBlock>
          ),
          options: recentSearch.map((m) => ({ label: m, value: m })),
        },
      ];
    }
    return (masterdata ?? []).map((m) => ({ label: m, value: m }));
  }, [masterdata, value]);
  useEffect(() => {
    const handle = () => {
      setValue('');
    };
    events.on('RESET_SEARCH', handle);
    return () => {
      events.off('RESET_SEARCH', handle);
    };
  }, [events]);
  return (
    <>
      <AutoComplete<string>
        id={props.id ?? 'candidate_job_search_input'}
        value={value}
        onSelect={(value) => {
          setValue(value as string);
          onChange?.(value as string);
        }}
        open={dropdownVisible}
        onDropdownVisibleChange={setDropdownVisible}
        // dropdownClassName="certain-category-search-dropdown"
        className={props.className}
        getPopupContainer={props.getPopupContainer}
        // dropdownMatchSelectWidth={500}
        // style={{ width: 250 }}
        backfill={true}
        onChange={setValue}
        options={options}>
        <Input
          prefix={props.prefix}
          allowClear={true}
          size={props.size}
          id={'home_search_input'}
          onChange={(event) => {
            const i = Date.now();
            lastSearch.current = i;
            setMasterdata([]);
            if (props.triggerOnChange) {
              onChange?.(event.target.value);
            }
            searchDebounced(connection, event.target.value ?? '', (data) => {
              if (i === lastSearch.current) {
                setMasterdata(data);
              }
            });
          }}
          placeholder={props.placeholder ?? 'Search Job'}
          onPressEnter={() => {
            if (!dropdownVisible || triggerOnEnter) {
              onChange?.(value);
            }
            setDropdownVisible(false);
          }}
        />
      </AutoComplete>
      {props.showButton && (
        <Button
          type="primary"
          className={styles.searchInputBarButton}
          id={'btn-search'}
          onClick={() => onChange?.(value)}>
          <SearchOutlined />
          {bp.lg && 'Search Now'}
        </Button>
      )}
    </>
  );
};

export const SearchInputBar: FC<Props> = (props) => {
  const context = useSearchContext();
  const events = useEvents();
  const { query } = context;
  const search = query?.search;
  const bp = Grid.useBreakpoint();
  const [value, setValue] = useState<string | undefined>(search);
  const { hideJobSelect } = props;
  useEffect(() => {
    setValue(search ?? '');
  }, [search]);

  useEffect(() => {
    const handle = () => {
      setValue('');
    };
    events.on('RESET_SEARCH', handle);
    return () => {
      events.off('RESET_SEARCH', handle);
    };
  }, [events]);

  return (
    <div className={styles.searchInputBar}>
      {bp.md && !hideJobSelect && (
        <Select
          value={context.type ?? 'jobs'}
          onChange={(value) => context.setType(value)}
          className={styles.searchInputBarSelect}
          options={[
            { label: 'Jobs', value: 'jobs' },
            // { label: 'Courses', value: 'courses' },
          ]}
          getPopupContainer={(props1) => props1.parentNode}
        />
      )}
      <SearchAutocomplete
        className={styles.searchInputBarSearch}
        value={value}
        showButton={true}
        triggerOnEnter={true}
        getPopupContainer={(props1) => props1.parentNode}
        onChange={(val) => context.search({ search: val })}
      />
    </div>
  );
};

export const SearchTypeSelector = () => {
  const uc = useUnderConstruction();
  return (
    <Tabs
      className={styles.serchTypeTabs}
      defaultActiveKey="1"
      activeKey="1"
      onChange={(activeKey) => {
        if (activeKey === '2') {
          uc();
        }
      }}>
      <Tabs.TabPane tab="Jobs" key="1" />
      <Tabs.TabPane tab="Courses" key="2" />
    </Tabs>
  );
};
