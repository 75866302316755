import React, { FC, useEffect, useState } from 'react';
import { Space, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TextBlock } from '../../uicomp/atmos/content-block/ContentBlock';

type Props = {
  value?: string;
  onChange?: (value?: string) => void;
};

export const SalaryDisplayInput: FC<Props> = (props) => {
  const [checked, setChecked] = useState<boolean>((props.value ?? 'Amounts') === 'Amounts');
  useEffect(() => setChecked((props.value ?? 'Amounts') === 'Amounts'), [props.value]);
  return (
    <Space size={'large'}>
      <Switch
        checked={checked}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={(c) => {
          setChecked(c);
          props.onChange?.(c ? 'Amounts' : 'Do Not Display');
        }}
      />
      <TextBlock strong>{checked ? 'Display' : 'Do Not Display'}</TextBlock>
    </Space>
  );
};
