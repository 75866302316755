import React, { FC, useEffect } from 'react';
import { Switch } from 'antd';
import styles from './AdapterStyle.module.less';

export interface ApproachVia {
  viaSms: boolean;
  viaWhatsApp: boolean;
}

export interface ApproachViaSwitchProps {
  info: any;
  value?: ApproachVia;
  onChange?: (value?: ApproachVia) => void;
}

export const ApproachViaSwitch: FC<ApproachViaSwitchProps> = (props) => {
  const { info, value, onChange } = props;

  useEffect(() => {
    if (
      !info?.whatsapp?.sameAsMobile &&
      info?.whatsapp?.whatsapp === undefined &&
      value?.viaWhatsApp
    ) {
      onChange?.({
        ...(value ?? { viaSms: false, viaWhatsApp: false }),
        viaWhatsApp: false,
      } as any);
    }
    if (!info?.phone && value?.viaSms) {
      onChange?.({
        ...(value ?? { viaSms: false, viaWhatsApp: false }),
        viaSms: false,
      } as any);
    }
  }, [info, info.mobile, info?.whatsapp?.sameAsMobile, info?.whatsapp?.whatsapp, onChange, value]);

  // const checkPhone = useMemo(() => {
  //   return !(info?.phone !== undefined && isValidPhoneNumber(info?.phone));
  // }, [info]);
  //
  // const whatsApp = info?.whatsapp?.sameAsMobile ? info?.phone : info?.whatsapp?.whatsAppNumber;
  return (
    <div className={styles.approachContainer}>
      <div hidden={!info?.eyeBall && info?.eyeBall !== undefined}>
        <div>
          <span className={styles.approachViaText}>Approach Via</span>
        </div>
        <div className={styles.approachBtnContainer}>
          <div className={styles.approachSwitchContainer}>
            <div className={styles.approachText}>SMS</div>
            <Switch
              id={'profile-switch-approach-sms'}
              className="approach-via-switch"
              disabled={info?.phone === undefined}
              onChange={(val) =>
                onChange?.({
                  ...(value ?? { viaSms: false, viaWhatsApp: false }),
                  viaSms: val,
                } as any)
              }
              checked={value?.viaSms}
            />
          </div>
          <div className={styles.approachSwitchContainer}>
            <div className={styles.approachText}>WhatsApp</div>
            <Switch
              disabled={
                (info?.whatsapp?.sameAsMobile === false &&
                  info?.whatsapp?.whatsapp === undefined) ||
                info?.phone === undefined
              }
              id={'profile-switch-approach-whatsapp'}
              onChange={(val) =>
                onChange?.({
                  ...(value ?? { viaSms: false, viaWhatsApp: false }),
                  viaWhatsApp: val,
                } as any)
              }
              checked={value?.viaWhatsApp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
