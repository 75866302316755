import React, { FC } from 'react';
import { RoleInfo } from '../../../models/RecruiterModals';
import { ResponsiveModal } from '../../../components/ResponsiveModal/ResponsiveModal';
import { Checkbox, List } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styles from './RecruiterSettings.module.less';
import { AvailableRecruiterPermissions } from '../../../connection/ApplicationContext';

type Props = {
  value: RoleInfo;
  visible: boolean;
  onCancel?: (value: boolean) => void;
};
export const RolesInfoViewItem: FC<{ name: string; selected: boolean }> = (props) => {
  return (
    <>
      {props.selected ? (
        <CheckCircleOutlined style={{ color: 'green' }} />
      ) : (
        <CloseCircleOutlined style={{ color: 'red' }} />
      )}
      <div style={{ display: 'inline-block', paddingLeft: 8, flexGrow: 1 }}>{props.name}</div>
    </>
  );
};
export const RoleInfoView: FC<Props> = ({ value, visible, onCancel }) => {
  const { description, name, permissions } = value;
  return (
    <ResponsiveModal
      footer={null}
      title={'Role'}
      visible={visible}
      onCancel={() => onCancel?.(false)}>
      <div className={styles.pA16}>
        <h3>{name}</h3>
        <div>{description}</div>
        <List<string>
          dataSource={AvailableRecruiterPermissions}
          renderItem={(item) => (
            <List.Item key={item}>
              <RolesInfoViewItem
                name={item}
                selected={permissions && permissions.indexOf(item) !== -1}
              />
            </List.Item>
          )}
        />
      </div>
    </ResponsiveModal>
  );
};

export const PermissionsEditor: FC<{ value?: string[]; onChange?: (value?: string[]) => void }> = (
  props,
) => {
  const { value, onChange } = props;
  const publishChange = (item: string, checked: boolean) => {
    const current = value ? value : [];
    const newValue = checked ? [...current, item] : current.filter((v) => v !== item);
    onChange && onChange(newValue);
  };
  return (
    <List<string>
      dataSource={AvailableRecruiterPermissions}
      renderItem={(item) => (
        <List.Item key={item}>
          <Checkbox
            checked={value ? value.indexOf(item) !== -1 : false}
            onChange={(e) => publishChange(item, e.target.checked)}>
            {item}
          </Checkbox>
        </List.Item>
      )}
    />
  );
};
