import React, { FC } from 'react';
import styles from './RadioGroupCardButton.module.less';
import { Radio, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

type Props = {
  options: string[];
  value?: string;
  onChange?: (value: string) => void;
};
export const RadioGroupCardButton: FC<Props> = ({ value, onChange, options }) => {
  return (
    <div>
      {options.map((item, index) => (
        <Space key={index} className={styles.cardHolder} onClick={() => onChange?.(item)}>
          <Radio checked={item === value}>{item}</Radio>
          {item === value && <CheckOutlined className={styles.checkIcon} />}
        </Space>
      ))}
    </div>
  );
};
