import React, { FC, useCallback, useEffect, useState } from 'react';
import { InputNumber, Select } from 'antd';
import { Salary } from '../../models/UserModels';
import styles from './SalaryInput.module.less';

const { Option } = Select;

type Props = {
  value?: Salary;
  onChange?: (value?: Salary) => void;
};
export const SalaryInput: FC<Props> = ({ value, onChange }) => {
  const [type, setType] = useState<string>(value?.type ?? 'Month');
  const handleChange = useCallback(
    (val?: number) => {
      onChange?.(
        (val ?? 0) > 0
          ? {
              amount: val!,
              type,
            }
          : undefined,
      );
    },
    [onChange, type],
  );
  const onTypeChange = useCallback(
    (val: string) => {
      setType(val);
      onChange?.(
        value
          ? {
              amount: value.amount,
              type: val,
            }
          : undefined,
      );
    },
    [onChange, value],
  );
  useEffect(() => {
    if (value) {
      setType(value?.type ?? 'Month');
    }
  }, [value]);
  const selectBefore = (
    <Select value={type} className="select-before" onChange={onTypeChange}>
      <Option value="Month">Per Month</Option>
      <Option value="Day">Per Day</Option>
      <Option value="Hour">Per Hour</Option>
      <Option value="Year">Per Year</Option>
    </Select>
  );
  return (
    <InputNumber
      className={styles.salary}
      min={0}
      value={value?.amount}
      controls={false}
      placeholder={'Amount'}
      onChange={(e: any) => handleChange(e)}
      addonBefore={selectBefore}
    />
  );
};
