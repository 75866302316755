import React, { FC, useCallback, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import SuperAgent from 'superagent';
import { Spin } from 'antd';

type Props = {
  value: string;
  url?: boolean;
};
export const HtmlTemplateView: FC<Props> = ({ value, url }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<string>('');
  const fetchPage = useCallback(() => {
    setLoading(true);
    SuperAgent.get(value).then((value: SuperAgent.Response) => {
      setData(value.text);
      setLoading(false);
    });
  }, [value]);
  useEffect(() => {
    if (url === true) {
      fetchPage();
    } else {
      setData(value);
    }
  }, [fetchPage, url, value]);
  return <Spin spinning={loading}>{ReactHtmlParser(data) as any}</Spin>;
};
