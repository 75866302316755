import React, { FC, useCallback } from 'react';
import styles from './Logo.module.less';
import Utils from '../../../utils/Utils';
import Image from 'next/image';

type Props = {
  style?: any;
  size?: 'small' | 'medium' | 'large';
  src?: string;
};

export const Logo: FC<Props> = (props) => {
  const { size, src, style } = props;
  const getSize = useCallback((size?) => {
    if (size === 'small') {
      return 48;
    }
    if (size === 'large') {
      return 96;
    }
    return 72;
  }, []);
  return (
    // <img
    //   src={src}
    //   alt={'logo'}
    //   className={Utils.mergeClasses({
    //     [styles.Logo]: true,
    //     [styles.LogoSmall]: size === 'small',
    //     [styles.LogoMedium]: size === 'medium' || size === undefined,
    //     [styles.LogoLarge]: size === 'large',
    //   })}
    //   style={style}
    // />
    <Image
      src={src ?? ''}
      className={styles.Logo}
      alt={'logo'}
      style={style}
      width={getSize(size)}
      height={getSize(size)}
    />
  );
};
