import config from '../config';
import { useMemo } from 'react';
import moment from 'moment';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import MomentTz from 'moment-timezone';
import universalCookie from 'universal-cookie';
import TagManager from 'react-gtm-module';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import superagent from 'superagent';
import Config from '../config';

dayjs.extend(utc);
dayjs.extend(timezone);

export default class Utils {
  static CommonImageFileFormats = '.jpg,.jpeg,.png,.gif,.tif,.tiff,.bmp';
  static CommonDocumentFileFormats = '.docx,.doc,.pdf,.txt,.odt,.rtf';

  static capitalizeFirstLetter = (value: string) => {
    return (value ?? '')
      .split(' ')
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase())
      .join(' ');
  };
  static formatPhoneNumber = (value: string) => {
    return formatPhoneNumberIntl(value)
      .split(' ')
      .map((v, i) => (i === 1 ? `(${v})` : v))
      .join(' ');
  };

  static mergeClasses = (
    ...classNames: (string | undefined | Record<string, boolean | undefined>)[]
  ): string => {
    return classNames
      .map((value) => {
        if (typeof value !== 'string' && value !== undefined) {
          const v: Record<string, boolean | undefined> = value;
          const s = Object.entries(v)
            .filter((e) => e[1])
            .map((e) => e[0])
            .join(' ');
          return s.trim() === '' ? undefined : s.trim();
        } else {
          return value;
        }
      })
      .filter((value) => !!value)
      .join(' ');
  };

  static removeNull = <T>(value: T): any => {
    if (value !== null && value !== undefined && typeof value === 'object') {
      return Object.fromEntries(
        Object.entries(value)
          .map((v) => [v[0], Utils.removeNull(v[1])])
          .filter((v) => v[1] !== null),
      );
    }
    return value as any;
  };

  static getFileUrl(path?: string): string | undefined {
    if (!path) return undefined;
    return `${config.api.fileUrl}${path}`;
  }

  static useOmitFields(value: any, ...fields: string[]) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      const val = { ...value };
      fields.forEach((f) => delete val[f]);
      return val;
    }, [value, fields]);
  }

  static mergeArrays<T>(current: T[], newValues: T[], idExtractor: (t: T) => string | number): T[] {
    const cur = [...current];
    for (const n of newValues) {
      const index = cur.findIndex((value) => idExtractor(value) === idExtractor(n));
      if (index === -1) {
        cur.push(n);
      } else {
        cur[index] = n;
      }
    }
    return cur;
  }

  static getChanges<T>(
    currentValue: T,
    newValue: T,
    omitFields?: string[],
  ): Partial<T> | undefined {
    const ret: Partial<T> = {};
    Object.entries(newValue as any)
      .filter(([key]) => (omitFields ?? []).indexOf(key) === -1)
      .filter(([key]) => (currentValue as any)[key] !== (newValue as any)[key])
      .forEach(([key, value]) => ((ret as any)[key] = value));
    return Object.keys(ret).length > 0 ? ret : undefined;
  }

  static cleanHtml = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return new XMLSerializer().serializeToString(doc);
    // return html.replace(/<br>/g, '<br/>');
  };
  static getTime = (modifiedTime: any) => {
    return moment.utc(modifiedTime).local().format('YYYY-MMM-DD h:mm A');
  };

  static deleteFields = <T = any, R = T>(value: T, ...fields: (keyof T | string)[]): R => {
    fields.forEach((f) => delete (value as any)[f]);
    return value as unknown as R;
  };

  static selectFields = <T = any, R = T>(value: T, ...fields: (keyof T | string)[]): R => {
    const val: any = {};
    fields.forEach((f) => (val[f] = (value as any)[f]));
    return val as unknown as R;
  };

  static dateToString(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  static formatMonth(date: string) {
    if (date === 'Present') {
      return date;
    }
    return moment(date, 'YYYY-MM-DD').format('MMM/YYYY');
  }

  static formatDate(date: string | number) {
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  static epochToDateTime(epoch: number, longFormat?: boolean) {
    return moment(epoch).format(
      longFormat ? 'dddd, MMMM Do, YYYY h:mm:ss A' : 'DD/MM/YYYY h:mm:ss A',
    );
  }

  static dateSince(epoch: number) {
    return moment(epoch).fromNow();
  }

  static formatDuration(to: number, from: number) {
    return moment.duration(moment(to).diff(moment(from))).format();
    // moment.duration(moment(to).diff(moment(from), 'months'), 'months');
    //.format('y [yr] M [mo]')
    //.replace(/s/g, '')
  }

  static s3LinkToHttp(link: string) {
    const s3Link = link.replace('s3://', '');
    return `${config.api.fileUrl}/${s3Link.trim()}`;
  }

  static s3LinkToHttpX(link: string) {
    const s3Link = link.replace('s3://', '');
    return `${config.fileStoreUrl}/${s3Link.trim()}`;
  }

  static moment = (inp?: MomentTz.MomentInput) => {
    return MomentTz(inp).tz('Asia/Colombo');
  };

  static dayJs = (inp?: any) => {
    return dayjs(inp).tz('Asia/Colombo');
  };

  static getSocialMediaLink = (
    type: 'fb' | 'ig' | 'youtube' | 'pinterest' | 'twitter' | 'LinkedIn ',
  ) => {
    switch (type) {
      case 'LinkedIn ':
        return 'https://www.linkedin.com/company/careers360lk';
      case 'twitter':
        return 'https://twitter.com/Careers360LK';
      case 'pinterest':
        return 'https://www.pinterest.com/c360socials/';
      case 'ig':
        return 'https://www.instagram.com/careers360lk/';
      case 'youtube':
        return 'https://www.youtube.com/channel/UCQ_AycMcTg6I3T-jW3yr4-g';
      case 'fb':
        return 'https://www.facebook.com/The-BIG-Question-107562821697702';
      default:
        return '';
    }
  };

  static normalizeURIComponent = (uriComponent: string) => {
    if (!uriComponent) {
      return uriComponent;
    }
    return (uriComponent ?? '')
      .replace(/[\W_]+/g, '-')
      .replace(/[-]+/g, '-')
      .toLowerCase();
  };
  static getOpenSansFont = (): any => {
    return {
      family: 'OpenSans',
      fonts: [
        ['Thin', 100],
        ['Light', 300],
        ['Regular', 400],
        ['Medium', 500],
        ['Bold', 700],
        ['Black', 900],
      ].flatMap((f) => [
        {
          src: `${config.frontendUrl}/font/OpenSans-${f[0]}.ttf`,
          fontWeight: f[1],
          fontStyle: 'normal',
        },
        {
          src: `${config.frontendUrl}/font/OpenSans-${f[0]}Italic.ttf`,
          fontWeight: f[1],
          fontStyle: 'italic',
        },
      ]),
      // src: 'https://fonts.gstatic.com/s/Roboto/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
    };
  };
  static getReactPdfFont = (): any => {
    return {
      family: 'Roboto',
      fonts: [
        ['Thin', 100],
        ['Light', 300],
        ['Regular', 400],
        ['Medium', 500],
        ['Bold', 700],
        ['Black', 900],
      ].flatMap((f) => [
        {
          src: `${config.frontendUrl}/font/Roboto-${f[0]}.ttf`,
          fontWeight: f[1],
          fontStyle: 'normal',
        },
        {
          src: `${config.frontendUrl}/font/Roboto-${f[0]}Italic.ttf`,
          fontWeight: f[1],
          fontStyle: 'italic',
        },
      ]),
      // src: 'https://fonts.gstatic.com/s/Roboto/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
    };
  };

  static getCampaignDataFromUtm = (): any => {
    const cookies = new universalCookie();
    const utm = cookies.get('utm') ?? {};
    if (!utm.utm_source) {
      return undefined;
    }

    return {
      source: utm.utm_source,
      medium: utm.utm_medium,
      campaign: utm.utm_campaign,
      term: utm.utm_term,
      content: utm.utm_content,
    };
  };

  static sendGtmEvent = (data: any) => {
    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: data,
    });
  };

  static sendGtmCustomEvent = (
    name: string,
    data: Record<string, any>,
    outerData?: Record<string, any>,
  ) => {
    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        event: `custom:${name}`,
        eventParams: {
          eventName: name,
          ...data,
        },
        ...outerData,
      },
    });
  };

  static formatNumber(val: number, decimals?: number) {
    if (val === undefined) {
      val = 0;
    }
    return Number(val.toFixed(decimals ?? 0)).toLocaleString('en', {
      minimumFractionDigits: decimals ?? 0,
    });
  }

  static async getLandingPageData() {
    try {
      const response = await superagent.get(`${Config.fileStoreUrl}/files/landing-page/data.json`);
      console.log('Response landing', response.body);
      return response.body;
    } catch (error: any) {
      console.error('Error fetching landing page data:', error.message);
      throw error;
    }
  }

  static isLast7Days(timestamp: number) {
    const now = dayjs();
    const providedDate = dayjs.unix(Math.floor(timestamp / 1000));
    const difference = now.diff(providedDate, 'day');
    return difference < 7;
  }

  static getTimeStampInSecconds(timestamp: number) {
    // console.log('GET TIMESTAMP IN SECONDS', (timestamp - (timestamp % 1000)) / 1000);
    // return (timestamp - (timestamp % 1000)) / 1000;
    return Math.floor(timestamp / 1000);
  }
  static getFullName(firstName, lastName): string {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else if (firstName) {
      return firstName;
    } else if (lastName) {
      return lastName;
    } else {
      return '';
    }
  }
}
