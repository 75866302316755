import React, { FC, useCallback, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Grid } from 'antd';
import moment from 'moment';
import stylesModule from './AdapterStyle.module.less';
import dayjs from 'dayjs';

const styles: any = stylesModule;

export type ExperiencePeriod = {
  from: string;
  to?: string;
  present: boolean;
};

type Props = {
  value?: ExperiencePeriod;
  onChange?: (value?: ExperiencePeriod) => void;
  presentText?: string;
  fromLabel?: string;
  toLabel?: string;
  hidePresent?: boolean;
  enableFuture?: boolean;
  startDateId?: string;
  endDateId?: string;
  checkboxId?: string;
  labelId?: string;
};

const { useBreakpoint } = Grid;

export const ExperiencePeriodInput: FC<Props> = (props) => {
  const {
    value,
    onChange,
    presentText,
    fromLabel,
    toLabel,
    hidePresent,
    startDateId,
    endDateId,
    checkboxId,
    labelId,
  } = props;
  const [from, setFrom] = useState<string | undefined>(value?.from);
  const [to, setTo] = useState<string | undefined>(value?.to);
  const [present, setPresent] = useState<boolean>(value?.present === true);
  const bp = useBreakpoint();

  useEffect(() => {
    if (value) {
      setFrom(value.from);
      setTo(value.to);
      setPresent(value.present);
    }
  }, [value]);

  const publishValueChange = useCallback(
    (val: any) => {
      if (value !== val) {
        if (val.from === undefined && val.to === undefined && val.present === false) {
          onChange?.(undefined);
        } else {
          onChange?.(val);
        }
      }
    },
    [onChange, value],
  );

  const disabledDates = useCallback((current: any) => {
    return current && current > moment().endOf('day');
  }, []);

  const disabledTo = useCallback(
    (current: any) => {
      if (props.enableFuture) {
        if (from) {
          return current < moment(from);
        } else {
          return false;
        }
      } else {
        if (from) {
          return current < moment(from) || current > moment();
        } else {
          return current && current > moment().endOf('day');
        }
      }
    },
    [from, props.enableFuture],
  );

  const handleFromChange = useCallback(
    (date: any) => {
      const dt = date ? date.format('YYYY-MM-DD') : undefined;
      setFrom(dt);
      if (present) {
        publishValueChange({ from: dt, to: undefined, present });
      } else {
        if (to && dt > to) {
          setTo(dt);
          publishValueChange({ from: dt, to: undefined, present: false });
        } else {
          publishValueChange({ from: dt, to, present: false });
        }
      }
      // if (!dt) {
      //   publishValueChange(undefined);
      // } else if (present) {
      //   publishValueChange({ from: dt, to: undefined, present });
      // } else if (to) {
      //   if (dt > to) {
      //     setTo(dt);
      //     publishValueChange({ from: dt, to: dt, present: false });
      //   } else {
      //     publishValueChange({ from: dt, to, present: false });
      //   }
      // } else {
      //   publishValueChange(undefined);
      // }
    },
    [present, publishValueChange, to],
  );

  const handleToChange = useCallback(
    (date: any) => {
      const dt = date ? date.format('YYYY-MM-DD') : undefined;
      setTo(dt);
      if (present) {
        publishValueChange({ from, to: undefined, present });
      } else {
        publishValueChange({ from, to: dt, present });
      }
      // if (from) {
      //   if (present) {
      //     publishValueChange({ from, to: undefined, present });
      //   } else if (dt) {
      //     publishValueChange({ from, to: dt, present });
      //   } else {
      //     publishValueChange(undefined);
      //   }
      // } else {
      //   publishValueChange(undefined);
      // }
    },
    [from, present, publishValueChange],
  );

  const handlePresentChange = useCallback(
    (checked: boolean) => {
      setPresent(checked);
      if (checked) {
        publishValueChange({ from, to: undefined, present: checked });
      } else {
        publishValueChange({ from, to, present: checked });
      }
      // if (from) {
      //   if (checked) {
      //     publishValueChange({ from, to: undefined, present: checked });
      //   } else if (to) {
      //     publishValueChange({ from, to, present: checked });
      //   } else {
      //     publishValueChange(undefined);
      //   }
      // } else {
      //   publishValueChange(undefined);
      // }
    },
    [from, publishValueChange, to],
  );
  return (
    <div className={styles.experience}>
      <div className={styles.experienceTimeRange}>
        <DatePicker
          id={startDateId ?? 'date_picker_start_date'}
          className={styles.dateRangePicker}
          disabledDate={disabledDates}
          placeholder={fromLabel ?? 'Start Date'}
          defaultValue={value ? dayjs(value.from) : undefined}
          onChange={handleFromChange}
          format={'DD/MM/YYYY'}
          inputReadOnly={!bp.lg}
        />
        <DatePicker
          id={endDateId ?? 'date_picker_end_date'}
          disabledDate={disabledTo}
          disabled={present}
          className={styles.dateRangePicker}
          placeholder={toLabel ?? 'End Date'}
          value={present ? undefined : to ? dayjs(to) : undefined}
          onChange={handleToChange}
          format={'DD/MM/YYYY'}
          inputReadOnly={!bp.lg}
        />
      </div>
      {!hidePresent && (
        <Checkbox
          id={checkboxId ?? 'date_picker_checkbox'}
          style={{ maxWidth: presentText ? `${presentText.length}ch` : '21ch' }}
          onChange={(e) => handlePresentChange(e.target.checked)}
          checked={present}>
          <p className={'mt-0'} id={labelId ?? 'date_picker_current_text'}>
            {presentText ?? 'I currently work here'}
          </p>
        </Checkbox>
      )}
    </div>
  );
};
