/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useState } from 'react';
import styles from './ResumeUploader.module.less';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import FileUploadEx from '../FileUpload/FileUpload';
import { ResumeCard } from './ResumeCard';
import { ResumeType } from '../../models/UserModels';
import moment from 'moment';
import Utils from '../../utils/Utils';

type Props = {
  value?: ResumeType[];
  onChange?: (value: ResumeType[]) => void;
  title?: string;
};
export const ResumeUploader: FC<Props> = (props) => {
  const [error, seterror] = useState<string>('');
  const [limit, setLimit] = useState<number>(3);
  const [toggled, setToggled] = useState<boolean>(false);

  const handlerChange = useCallback(
    (tog: boolean, url?: string) => {
      if (url) {
        const temp = [...(props.value ?? [])];
        temp.push({ url, time: moment().unix() });
        props.onChange?.(temp);
        if (!tog) {
          setLimit(3);
        } else {
          setLimit(temp.length);
        }
      }
    },
    [props],
  );
  const handlerRemove = useCallback(
    (time) => {
      const temp = [...(props.value ?? [])];
      const x = temp.filter((v) => v.time !== time);
      props.onChange?.(x);
    },
    [props],
  );

  const handleChekbox = useCallback(async (time, status, data) => {
    if (data?.length !== 0) {
      const temp = [...(data ?? [])];
      temp.forEach((obj) => {
        if (obj.time === time) {
          obj.status = status;
        }
      });
      await props.onChange?.(temp);
    }
  }, []);
  const uploadValidation = useCallback((file) => {
    seterror('');
    const isTypeCheck =
      file.type === 'application/pdf' ||
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type === 'application/msword' ||
      file.type === 'application/rtf' ||
      file.type === 'application/vnd.oasis.opendocument.text' ||
      file.type === 'text/plain';
    if (!isTypeCheck) {
      seterror('Must match file type');
    }
    const isSize = file.size / 1024 / 1024 < 2.5;
    if (!isSize) {
      seterror('Must match file Size');
    }
    return isSize && isTypeCheck;
  }, []);

  return (
    <div>
      <span className="text-[12px] font-medium">
        {props.value && props.value?.length > 0
          ? `Select which ${
              props.title == 'resumes' ? props.title : 'cover letters'
            } headhunters can view`
          : ''}
      </span>

      {[...(props.value ?? [])].slice(0, limit).map((value, index) => (
        <>
          <ResumeCard
            type={props.title}
            status={value?.status ?? true}
            onChange={(e) => handleChekbox(value.time, e, props.value)}
            title={value.url.split('/').pop() ?? ''}
            key={index}
            onDelete={() => handlerRemove(value.time)}
            onDownload={() => {
              window.open(value.url, '_blank');
            }}
            text={moment.unix(value.time).format('L')}
          />
          {[...(props.value ?? [])].slice(0, limit).length === index + 1 &&
            [...(props.value ?? [])].length > 3 &&
            props.title && (
              <div
                onClick={() => {
                  if (limit === 3) {
                    setToggled(true);
                    setLimit([...(props.value ?? [])].length);
                  } else {
                    setToggled(false);
                    setLimit(3);
                  }
                }}
                className="text-center flex items-center justify-center">
                {limit === 3 && (
                  <span className="cursor-pointer">
                    Show all {[...(props.value ?? [])].length}{' '}
                    {props.title == 'resumes' ? 'resumes' : 'cover letters'}{' '}
                  </span>
                )}
                {limit !== 3 && <span className="cursor-pointer">Show less</span>}
                <i
                  className={`cursor-pointer icon-chevron-down-1 transform ${
                    limit !== 3 ? 'rotate-180' : 'rotate-0'
                  } text-[26px]`}
                />
              </div>
            )}
        </>
      ))}

      <>
        <div className={styles.uploaderHolder}>
          <div className={styles.uploadInfoText}>
            <CloudUploadOutlined className={styles.uploadIcon} />
            <br />
            <div className={styles.uploadInfoText}>Drag & Drop</div>
          </div>
          <div className={styles.fileTypeText}>
            File types: .docx, .doc, .pdf, .txt, .odt or .rtf No larger than 2.5 MB
          </div>
          <FileUploadEx
            onChange={(e) => handlerChange(toggled, e)}
            accept={Utils.CommonDocumentFileFormats}
            acceptSize={2.5}
            beforeUpload={uploadValidation}>
            <Button size={'small'} type={'primary'}>
              Browse
            </Button>
          </FileUploadEx>
        </div>
        <div>
          <span className={styles.errorText}>{error}</span>
        </div>
      </>
    </div>
  );
};
