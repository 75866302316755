import React, { FC, useCallback } from 'react';
import styles from './MobileVerificationInput.module.less';
import { Checkbox } from 'antd';
import { WhatsAppData } from '../../models/UserModels';
import { PhoneInput } from '../PhoneInput/PhoneInput';

type Props = {
  value?: WhatsAppData;
  onChange?: (value: WhatsAppData) => void;
  placeholder?: string;
  defaultCountry?: string;
  mobile?: string;
};
export const WhatsAppInput: FC<Props> = (props) => {
  const { value, defaultCountry, placeholder, onChange, mobile } = props;
  const handlerInputChange = useCallback(
    (text) => {
      const temp = { ...value };
      temp.whatsapp = text;
      onChange?.(temp);
    },
    [onChange, value],
  );
  const handlerCheckBox = useCallback(() => {
    const temp = { ...value };
    temp.sameAsMobile = !temp.sameAsMobile;
    if (temp.sameAsMobile) {
      temp.whatsapp = mobile;
    } else {
      temp.whatsapp = undefined;
    }
    onChange?.(temp);
  }, [mobile, onChange, value]);
  return (
    <div className={styles.inputHolder}>
      <PhoneInput
        disabled={value?.sameAsMobile}
        defaultCountry={defaultCountry}
        placeholder={placeholder}
        style={{ width: '100%' }}
        onChange={handlerInputChange}
        value={(value?.sameAsMobile ? mobile : value?.whatsapp) ?? ''}
      />
      <Checkbox
        onClick={handlerCheckBox}
        disabled={mobile === undefined}
        checked={value?.sameAsMobile}>
        Same as mobile
      </Checkbox>
    </div>
  );
};
