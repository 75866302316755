import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import styles from './AdapterStyle.module.less';
import FileUploadEx from '../../FileUpload/FileUpload';
import { FormOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { Modal } from 'antd';
import Utils from '../../../utils/Utils';
import Image from 'next/image';

type Props = {
  value?: string;
  maxSize?: number;
  crop?: boolean;
  width?: number;
  height?: number;
  fileTypes?: string[];
  onChange?: (value: string) => void;
};

export const AvatarInput: FC<PropsWithChildren<Props>> = (props) => {
  const allowedTypes = useMemo(() => {
    return props.fileTypes ?? ['jpg', 'png', 'tif', 'gif'];
  }, [props.fileTypes]);

  const uploadValidator = useCallback(
    (file: RcFile) => {
      const parts = file.name.split('.');
      const extension = parts[parts.length - 1];
      if (allowedTypes.indexOf(extension) === -1) {
        Modal.error({
          content: `Image file should be ${allowedTypes.toString()}`,
        });
        return false;
      }
      if (file.size > (props.maxSize ?? 2.5e6)) {
        Modal.error({
          content: `Image size should be less than ${
            props.maxSize ? (props.maxSize / 10) ^ 6 : '2.5'
          } Mb`,
        });
        return false;
      }
      return true;
    },
    [allowedTypes, props.maxSize],
  );

  return (
    <div className={styles.logoHolder}>
      {props.value && <Image alt={'logo'} src={props.value} width={178} height={178} />}
      <FileUploadEx
        value={props.value}
        onChange={(url) => props.onChange?.(url as any)}
        image={{
          crop: props.crop,
          width: props.width ?? 178,
          height: props.height ?? 178,
        }}
        accept={Utils.CommonImageFileFormats}
        beforeUpload={uploadValidator}>
        <div className={[styles.editButton, styles.editPositionAvatar].join(' ')}>
          <FormOutlined className={styles.editIcon} />
        </div>
      </FileUploadEx>
    </div>
  );
};
