import React, { FC } from 'react';
import styles from './styles.module.less';
import { Input } from 'antd';

type Props = {
  value?: string;
  onChange: (value: any) => void;
  placeholder?: string;
  number: number;
};
export const StyleNumberText: FC<Props> = (props) => {
  const { value, placeholder, onChange, number } = props;
  return (
    <div className={styles.inputHolder}>
      <div className={styles.listnumborder}>
        <p>{number > 8 ? number + 1 : `0${number + 1}`}</p>
      </div>
      <Input
        placeholder={placeholder}
        style={{ width: '100%' }}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
