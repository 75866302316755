import React, {
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { JobAlertCreateParams } from '../../../models/JobPostModals';
import { ResponsiveModal } from '../../../components/ResponsiveModal/ResponsiveModal';
import { TextBlock } from '../../../uicomp/atmos/content-block/ContentBlock';
import { Button, Modal, Radio, Space } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useConnection } from '../../../connection/Application';
import Utils from '../../../utils/Utils';
// import { useRouter } from 'next/router';
import { AlertManagementView } from '../../JobAlertView/AlertManagementView';

export type JobAlertModalInstance = {
  show: (params: JobAlertCreateParams) => void;
};

type Props = {
  onAlertCreated: (alertId: string) => void;
};

const JobAlertModal: ForwardRefRenderFunction<JobAlertModalInstance, Props> = (
  { onAlertCreated },
  ref,
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<JobAlertCreateParams>();
  const connection = useConnection();
  // const history = useRouter();
  const [frequency, setFrequency] = useState<string>('weekly');
  const [editAlertVisible, setEditAlertVisible] = useState<boolean>(false);
  useImperativeHandle(
    ref,
    () => ({
      show: (alertParams) => {
        setVisible(true);
        setLoading(false);
        setFrequency('weekly');
        setParams(alertParams);
      },
    }),
    [],
  );

  const createAlert = useCallback(() => {
    setLoading(true);
    const createParams: JobAlertCreateParams = Utils.selectFields(
      params,
      'search',
      'sector',
      'jobCategory',
      'company',
      'location',
      'salary_type',
      'SalaryUp',
      'SalaryDown',
      'graduate',
      'jobType',
      'jobFunction',
    );

    createParams.frequency = frequency;

    connection
      .post('job-post/alerts', createParams)
      .then((value) => {
        onAlertCreated(value.alertId);
      })
      .catch(() => {
        Modal.error({ title: 'Failed to create alert', content: 'Something went wrong!' });
      })
      .finally(() => {
        setVisible(false);
        setLoading(false);
      });
  }, [connection, frequency, onAlertCreated, params]);

  return (
    <>
      <ResponsiveModal
        width={500}
        title={
          <Space className={'text-[20px]'}>
            <BellOutlined />
            {'Adding Job Alerts!'}
          </Space>
        }
        open={visible}
        footer={
          <Space>
            <Button
              disabled={loading}
              onClick={() => {
                setVisible(false);
                setEditAlertVisible(true);
              }}>
              View All Alerts
            </Button>
            <Button loading={loading} onClick={createAlert} type="primary">
              Confirm
            </Button>
          </Space>
        }
        onCancel={() => setVisible(false)}
        onOk={createAlert}>
        <Space direction="vertical" size="large">
          <TextBlock>Alert Frequency</TextBlock>
          <Radio.Group onChange={(e) => setFrequency(e.target.value)} value={frequency}>
            <Radio value={'weekly'}>Weekly</Radio>
            <Radio value={'daily'}>Daily</Radio>
            <Radio value={'instant'}>Instant</Radio>
          </Radio.Group>
        </Space>
      </ResponsiveModal>
      <ResponsiveModal
        width={1000}
        open={editAlertVisible ?? false}
        title={'Manage Job Alerts'}
        onCancel={() => setEditAlertVisible(false)}
        footer={false}>
        <AlertManagementView />
      </ResponsiveModal>
    </>
  );
};

export const useJobAlertModal = () => useRef<JobAlertModalInstance>(null);

export default React.forwardRef<JobAlertModalInstance, Props>(JobAlertModal);
