import React, { FC, HTMLAttributes, useMemo } from 'react';
import Utils from '../utils/Utils';

type Props = {
  direction?: 'horizontal' | 'vertical';
  horizontalAlign?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'center' | 'bottom';
} & HTMLAttributes<HTMLDivElement>;

export const FlexBox: FC<Props> = (props) => {
  const { direction, horizontalAlign, verticalAlign } = props;
  const justifyContent = useMemo(
    () =>
      direction !== 'vertical'
        ? horizontalAlign === 'right'
          ? 'flex-end'
          : horizontalAlign === 'center'
          ? 'center'
          : 'flex-start'
        : verticalAlign === 'bottom'
        ? 'flex-end'
        : verticalAlign === 'center'
        ? 'center'
        : 'flex-start',
    [direction, horizontalAlign, verticalAlign],
  );
  const alignItems = useMemo(
    () =>
      direction === 'vertical'
        ? horizontalAlign === 'right'
          ? 'flex-end'
          : horizontalAlign === 'center'
          ? 'center'
          : 'flex-start'
        : verticalAlign === 'bottom'
        ? 'flex-end'
        : verticalAlign === 'center'
        ? 'center'
        : 'flex-start',
    [direction, horizontalAlign, verticalAlign],
  );

  const p = Utils.useOmitFields(props, 'direction', 'horizontalAlign', 'verticalAlign');

  return (
    <div
      {...p}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: props.direction === 'vertical' ? 'column' : 'row',
        justifyContent,
        alignItems,
        ...props.style,
      }}>
      {props.children}
    </div>
  );
};
