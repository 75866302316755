import React, { FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

export type RecruiterLayoutContextData = {
  rightVisible: boolean;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  rightCollapsed: boolean;
  setRightCollapsed: (value: boolean) => void;
  openKeys: string[] | undefined;
  setOpenKeys: (openKeys: string[] | undefined) => void;
};

export const RecruiterLayoutContext = React.createContext<RecruiterLayoutContextData>({} as any);

export const RecruiterLayoutContextProvider: FC<PropsWithChildren> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [rightCollapsed, setRightCollapsed] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[] | undefined>();
  const history = useRouter();
  const rightVisible = useMemo(
    () =>
      history.pathname.split('/').pop() === 'profile' ||
      history.pathname.split('/').pop() === '360-view',
    [history.pathname],
  );
  return (
    <RecruiterLayoutContext.Provider
      value={{
        collapsed,
        setCollapsed,
        rightCollapsed,
        setRightCollapsed,
        rightVisible,
        openKeys,
        setOpenKeys,
      }}>
      {props.children}
    </RecruiterLayoutContext.Provider>
  );
};

export const useRecruiterLayoutContext = () => useContext(RecruiterLayoutContext);
