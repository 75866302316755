import React, { useEffect, useRef } from 'react';
import { SchemaFormConfigProvider } from '../components/form/SchemaFormConfig';
import '../styles/globals.css';
import '../styles/schema-form.less';
import '../components/DataTable/DataTable.less';
import '../layout/webux/webux-layouts/webUx.less';
import '../components/AntdImgCrop/index.less';
import CommonAdapters from '../components/form/CommonAdapters';
import { ApplicationProvider } from '../connection/ApplicationContext';
import { CacheContextProvider } from '../connection/CacheContext';
import { RecruiterLayoutContextProvider } from '../layout/recruiter/RecruiterLayoutContext';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import '../styles/antd-overrides.css';
import { AgentLayoutContextProvider } from '../layout/fieldAgent/AgentLayoutContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { useRouter } from 'next/router';
import universalCookie from 'universal-cookie';
import config from '../config';
import { AUTH_TOKEN } from '../connection/Connection';
import jwtDecode from 'jwt-decode';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import { clarity } from 'react-microsoft-clarity';
import getConfig from 'next/config';

const queryClient = new QueryClient();

const rollbarConfig = {
  accessToken: '16680c0474b04448804ff0d46ac84830',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
  },
};

const controleTheme = {
  controlHeight: 54,
  controlHeightSM: 40,
  controlHeightLG: 65,
  paddingSM: 18,
  paddingMD: 24,
  paddingXS: 12,
};

const controleThemeHeight = {
  // controlHeight: 54,
  // controlHeightSM: 40,
  controlHeightLG: 40,
};

function MyApp({ Component, pageProps }: any) {
  const router = useRouter();
  const utmParams = useRef<any>(undefined);
  useEffect(() => {
    if (utmParams.current === undefined) {
      const q = router.query ?? {};
      const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = q;
      let utm = { utm_source, utm_medium, utm_campaign, utm_term, utm_content };
      const cookies = new universalCookie();
      if (utm.utm_source) {
        cookies.set('utm', JSON.stringify(utm), { path: '/' });
      } else {
        utm = cookies.get('utm') ?? {};
      }

      utmParams.current = utm;

      const authToken = cookies.get(AUTH_TOKEN);
      let userId = 'guest';
      if (authToken) {
        try {
          const user = jwtDecode<any>(authToken);
          userId = user.userId;
        } catch (e) {
          console.error('Auth token decode error');
        }
      }

      const tagManagerArgs: TagManagerArgs = {
        gtmId: 'GTM-T2QJLJ9',
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          envId: config.envId,
          userId,
        },
      };
      if (utm.utm_source) {
        tagManagerArgs.dataLayer = { ...tagManagerArgs.dataLayer, utm };
      }
      TagManager.initialize(tagManagerArgs);

      if (config.enableClarity !== 'false') {
        clarity.init(config.projectToken ?? '');
      }
    }
  }, [router.query]);
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#009E52',
            colorLink: '#009E52',
            colorLinkActive: '#009E52',
            colorLinkHover: '#03c266',
            borderRadius: 4,
            fontFamily: "'Roboto', sans-serif",
            // controlHeight: 54,
            // controlHeightSM: 40,
            // controlHeightLG: 65,
            fontSize: 16,
            fontSizeLG: 20,
            // paddingSM: 18,
            // paddingMD: 24,
            // paddingXS: 12,
            colorText: '#212121',
            colorTextSecondary: '#6B6B6B',
          },
          components: {
            Button: controleTheme,
            Input: controleTheme,
            DatePicker: controleThemeHeight,
            Select: controleThemeHeight,
          },
        }}>
        <StyleProvider hashPriority="low" ssrInline={true}>
          <Provider config={rollbarConfig}>
            <ErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <RecruiterLayoutContextProvider>
                  <AgentLayoutContextProvider>
                    <ApplicationProvider>
                      <CacheContextProvider>
                        <SchemaFormConfigProvider adapters={CommonAdapters.typeAdapters}>
                          <Component {...pageProps} />
                        </SchemaFormConfigProvider>
                      </CacheContextProvider>
                    </ApplicationProvider>
                  </AgentLayoutContextProvider>
                </RecruiterLayoutContextProvider>
              </QueryClientProvider>
            </ErrorBoundary>
          </Provider>
        </StyleProvider>
      </ConfigProvider>
    </>
  );
}

//hhhhhhhh
// function MyApp({ Component, pageProps, cacheData }: any) {
//   useEffect(() => {
//     TagManager.initialize({ gtmId: 'GTM-T2QJLJ9', dataLayerName: 'UserDataLayer' });
//   }, []);
//   return (
//     <>
//       <Provider config={rollbarConfig}>
//         <ErrorBoundary>
//           <QueryClientProvider client={queryClient}>
//             <RecruiterLayoutContextProvider>
//               <AgentLayoutContextProvider>
//                 <ApplicationProvider>
//                   <CacheContextProvider cacheData={cacheData}>
//                     <SchemaFormConfigProvider adapters={CommonAdapters.typeAdapters}>
//                       <Component {...pageProps} />
//                     </SchemaFormConfigProvider>
//                   </CacheContextProvider>
//                 </ApplicationProvider>
//               </AgentLayoutContextProvider>
//             </RecruiterLayoutContextProvider>
//           </QueryClientProvider>
//         </ErrorBoundary>
//       </Provider>
//     </>
//   );
// }
//vadsvdvafsvddvavv
// MyApp.getInitialProps = async (appContext: any) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//   const res = await superagent.get(`${Config.fileStoreUrl}/files/masterdata/data.json`).send();
//   const masterdata: Record<string, MasterDataObj[]> = res.body;
//   console.log('res.body', masterdata);
//   const mainType = Object.fromEntries((masterdata['1'] ?? []).map((v) => [v.value, v.id]));
//   const mainTypes = Object.fromEntries((masterdata['1'] ?? []).map((v) => [v.id, v.value]));
//   const enumValue: Record<string, Record<string, string>> = Object.fromEntries(
//     Object.entries(masterdata).map((v) => [
//       mainTypes[v[0]],
//       Object.fromEntries(v[1].map((c) => [c.id, c.value])),
//     ]),
//   );
//   return {
//     ...appProps,
//     cacheData: {
//       masterdata,
//       mainType,
//       enumValue,
//     },
//   };
// };
// MyApp.getInitialProps = async (co) => {
//   const res = await superagent.get(`${Config.fileStoreUrl}/files/masterdata/data.json`).send();
//   const masterdata: Record<string, MasterDataObj[]> = res.body;
//   const mainType = Object.fromEntries((masterdata['1'] ?? []).map((v) => [v.value, v.id]));
//   const mainTypes = Object.fromEntries((masterdata['1'] ?? []).map((v) => [v.id, v.value]));
//   const enumValue: Record<string, Record<string, string>> = Object.fromEntries(
//     Object.entries(masterdata).map((v) => [
//       mainTypes[v[0]],
//       Object.fromEntries(v[1].map((c) => [c.id, c.value])),
//     ]),
//   );
//   console.log('ENUMVALUE', enumValue);
//   return {
//     masterdata,
//     mainType,
//     enumValue,
//   };
// };
export default MyApp;
