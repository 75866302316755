import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
export default {
  version: process.env.REACT_APP_VERSION,
  recaptchaSiteKey: publicRuntimeConfig.recaptchaSiteKey,
  fileStoreUrl: publicRuntimeConfig.fileStoreUrl,
  frontendUrl: publicRuntimeConfig.frontendUrl,
  cookieDomain: publicRuntimeConfig.cookieDomain,
  seoEnabled: publicRuntimeConfig.seoEnabledseoEnabled === 'YES',
  envId: publicRuntimeConfig.envId,
  projectToken: publicRuntimeConfig.projectToken,
  enableClarity: publicRuntimeConfig.enableClarity,
  api: {
    baseUrl: publicRuntimeConfig.baseUrl,
    // baseUrl: 'http://localhost:4000/dev/api/v1',
    // baseUrl: 'https://wd0cgqku3l.execute-api.us-east-1.amazonaws.com/dev/api/v1',
    fileUrl: process.env.NEXT_PUBLIC_FILE_URL,
    // projectToken: 'kyi2yjkw6q',
  },
  // name: 'sachindu-dev',
};
