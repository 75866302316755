import React, { FC, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import Form, { FormSchema } from '../Form';
import { useForm } from 'antd/lib/form/Form';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};
const schema: FormSchema = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    options: {
      rules: [{ required: true, message: 'Email is required' }],
    },
  },
];
export const EmailChange: FC<Props> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = useForm();

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Input readOnly value={props.value} />
      <Button type={'primary'} onClick={() => setVisible(true)}>
        Change
      </Button>
      <Modal
        visible={visible}
        title={'Change Email'}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key="verify" type="primary">
            Verify
          </Button>,
        ]}>
        <div style={{ marginTop: 16 }}>
          <Form.Form form={form} layout={'vertical'} onFinish={(value) => props?.onChange?.(value)}>
            <Form.Items schema={schema} />
          </Form.Form>
        </div>
      </Modal>
    </div>
  );
};
